<template>

    <v-autocomplete
        :error-messages="validationErrors"
        :items="filteredCompanies"
        :label="label || $t('general.static.companies')"
        :loading="loading"
        :multiple="multiple"
        :value="value"
        dense
        hide-selected
        item-text="name"
        item-value="id"
        outlined
        @input="$emit('input', $event)"
    >
        <template v-slot:selection="data">
            <v-chip-group column>
                <v-chip
                    :input-value="data.selected"
                    close
                    color="primary"
                    small
                    v-bind="data.attrs"
                    @click="data.select"
                    @click:close="deselect(data.item)"
                >
                    {{ data.item.name }}

                    <span class="ml-1 caption grey--text text--lighten-2">
                        {{ $_.truncate(data.item.address.street, {length: 12}) }} {{ data.item.address.street_number }}
                    </span>
                </v-chip>
            </v-chip-group>
        </template>

        <template v-slot:item="{ item }">
            <v-list-item-content>
                <v-list-item-title>
                    {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="item.address">
                    {{ item.address.asString || $t('company.static.noAddress') }}
                </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-icon v-if="! item.hasTrialEnded" class="caption grey--text">
                {{ $t('settings.static.trialUntil', {date: $moment(item.trial_ends_at).format('DD-MM-YYYY')}) }}
            </v-list-item-icon>
            <v-list-item-icon v-else-if="! item.isActive" class="caption grey--text">
                {{ $t('general.static.inactive') }}
                <v-icon color="error" small>
                    mdi-close
                </v-icon>
            </v-list-item-icon>
        </template>
    </v-autocomplete>

</template>

<script>
export default {
    props: {
        value: {},
        validationErrors: Array,
        multiple: Boolean,
        label: String,
        excluded: Array,
        excludeChildren: Boolean,
        excludeInactive: Boolean,
    },

    data() {
        return {
            companies: [],

            loading: false,
        }
    },

    computed: {
        filteredCompanies() {
            return _.filter(this.companies, (company) => {
                if (this.excludeChildren && company.parent_id != null) return false;

                if (this.excludeInactive && !company.isActive) return false;

                if (!this.excluded) return true;

                return _.indexOf(this.excluded, company.id) < 0;
            });
        }
    },

    mounted() {
        this.load();
    },

    methods: {
        load() {
            this.loading = true;

            axios.get(`/api/companies?with=[address]&appends=[isActive,hasTrialEnded]&order_by=name&order=asc`)
                .then((response) => {
                    this.companies = response.data.items;
                })
                .catch(() => {
                    this.$root.showSnackbar(this.$t('settings.static.loadCompaniesFailed'), 'error');
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        deselect(company) {
            this.$emit(
                'input',
                this.multiple
                    ? _.filter(this.value, (id) => id != company.id)
                    : null
            );
        },
    },
}
</script>
