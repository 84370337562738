<template>

    <v-card class="text-center" elevation="3" outlined width="600">
        <v-card-title class="justify-center primary--text">
            {{ $t('auth.static.newAccount') }}
        </v-card-title>
        <v-card-subtitle>
            {{ $t('auth.static.newAccountDescription') }}
        </v-card-subtitle>

        <v-card-text>
            <v-text-field
                id="first_name"
                v-model="account.first_name"
                :error-messages="validationErrors['first_name']"
                :label="$t('general.static.firstName')"
                autofocus
                dense
                outlined
            ></v-text-field>

            <v-text-field
                id="last_name"
                v-model="account.last_name"
                :error-messages="validationErrors['last_name']"
                :label="$t('general.static.lastName')"
                dense
                outlined
            ></v-text-field>

            <v-text-field
                id="company"
                v-model="account.company_name"
                :error-messages="validationErrors['company_name']"
                :label="$t('auth.static.company')"
                dense
                outlined
            ></v-text-field>

            <v-text-field
                id="email"
                v-model="account.email"
                :error-messages="validationErrors['email']"
                :label="$t('auth.static.email')"
                dense
                outlined
            ></v-text-field>

            <v-text-field
                id="password"
                v-model="account.password"
                :error-messages="validationErrors['password']"
                :label="$t('auth.static.password')"
                :type="passwordInputType"
                dense
                outlined
            >
                <template v-slot:append>
                    <v-icon tabindex="-1"
                            @click="passwordInputType = passwordInputType == 'password' ? 'text' : 'password'">
                        {{ passwordInputType == 'password' ? 'mdi-eye' : 'mdi-eye-off' }}
                    </v-icon>
                </template>
            </v-text-field>

            <v-text-field
                id="password-confirmation"
                v-model="account.password_confirmation"
                :error-messages="validationErrors['password_confirmation']"
                :label="$t('auth.static.confirmPassword')"
                :type="passwordConfirmationInputType"
                dense
                outlined
            >
                <template v-slot:append>
                    <v-icon
                        tabindex="-1"
                        @click="passwordConfirmationInputType = passwordConfirmationInputType == 'password' ? 'text' : 'password'">
                        {{ passwordConfirmationInputType == 'password' ? 'mdi-eye' : 'mdi-eye-off' }}
                    </v-icon>
                </template>
            </v-text-field>

            <v-btn
                :loading="registering"
                block
                class="my-3"
                color="primary"
                @click="register()"
            >
                {{ $t('auth.actions.createAccount') }}
            </v-btn>

            <v-btn
                color="accent"
                text
                to="login"
            >
                {{ $t('auth.actions.haveAccountQuestion') }}
            </v-btn>
        </v-card-text>
    </v-card>

</template>

<script>
export default {
    data() {
        return {
            passwordInputType: 'password',
            passwordConfirmationInputType: 'password',

            account: {
                first_name: null,
                last_name: null,
                company_name: null,
                email: null,
                password: null,
                password_confirmation: null,
            },

            validationErrors: {},

            registering: false,

            snackbar: {
                text: null,
                color: null
            }
        }
    },

    methods: {
        register() {
            this.registering = true;

            axios.post(`/api/auth/register?locale=${this.$i18n.locale}`, this.account)
                .then(async (response) => {
                    this.$root.showSnackbar(this.$t('auth.static.registrationSuccessfull'), 'success');

                    this.$router.replace(/**/{name: 'Login'});
                })
                .catch((error) => {
                    if (error.response.status == 422) {
                        this.validationErrors = error.response.data.errors;
                    }
                })
                .finally(() => {
                    this.registering = false;
                });
        }
    }
}
</script>
