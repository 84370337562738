<template>

    <v-card>
        <v-overlay :value="loading || impersonatingUserId" absolute color="white"></v-overlay>

        <v-card-text>
            <v-text-field
                v-model="search"
                :label="$t('general.static.search')"
                append-icon="mdi-magnify"
                color="primary"
                dense
                hide-details
                outlined
            ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-data-table
            :footer-props="{ itemsPerPageOptions: [10, 25, 50] }"
            :headers="headers"
            :items="users"
            :loading="loading"
            :options.sync="dataTableOptions"
            :server-items-length="totalUsers"
        >
            <template v-slot:item.first_name="{ item }">
                {{ item.name }}
            </template>

            <template v-slot:item.activated_at="{ item }">
                <v-icon :color="item.activated_at ? 'success' : 'grey'">
                    {{ item.activated_at ? 'mdi-check' : '' }}
                </v-icon>
                <span v-if="item.activated_at" class="grey--text">
                    {{ $moment(item.activated_at).format('DD-MM-YYYY') }}
                </span>
            </template>

            <template v-slot:item.companies_count="{ item }">
                <v-chip :color="item.active_companies_count == item.companies_count ? 'success' : 'warning'" small>
                    {{ item.active_companies_count }} / {{ item.companies_count }}
                </v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
                <div class="text-no-wrap">
                    <v-btn
                        v-if="! authenticatedUser.is_impersonated"
                        :disabled="item.id == authenticatedUser.id"
                        :loading="impersonatingUserId == item.id"
                        class="mr-2"
                        color="primary"
                        icon
                        small
                        @click="impersonate(item)"
                    >
                        <v-icon
                            small
                        >
                            mdi-shield-account
                        </v-icon>
                    </v-btn>
                    <v-icon
                        class="mr-2"
                        small
                        @click="editUser(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        :disabled="item.id == authenticatedUser.id"
                        small
                        @click="confirmDeleteUser(item)"
                    >
                        mdi-delete
                    </v-icon>
                </div>
            </template>
        </v-data-table>

        <v-divider></v-divider>

        <v-card-actions class="pa-4">
            <v-spacer></v-spacer>

            <v-btn color="primary" @click="makeUser">
                {{ $t('settings.actions.addUser') }}
            </v-btn>
        </v-card-actions>

        <user-dialog
            v-model="showUserDialog"
            :user="selectedUser"
            @created="load"
            @updated="$root.loadAuthenticatedUser(true);load()"
        ></user-dialog>

        <confirm-delete-dialog
            v-model="showConfirmDeleteDialog"
            :user="selectedUser"
            @deleted="$root.loadAuthenticatedUser(true);load()"
        ></confirm-delete-dialog>
    </v-card>

</template>

<script>
import UserDialog from './users/UserDialog';
import ConfirmDeleteDialog from './users/ConfirmDeleteDialog';

export default {
    components: {UserDialog, ConfirmDeleteDialog},

    data() {
        return {
            users: [],
            totalUsers: 0,

            loading: false,

            impersonatingUserId: null,

            dataTableOptions: {
                sortBy: ['first_name'],
                sortDesc: [false],
                multiSort: false,
                page: 1,
                itemsPerPage: 25
            },

            search: null,
            searchDebounced: null,
            searchColumns: [
                'first_name', 'last_name', 'email'
            ],

            selectedUser: null,

            showUserDialog: false,
            showConfirmDeleteDialog: false,
        }
    },

    computed: {
        authenticatedUser() {
            return this.$root.user;
        },

        headers() {
            return [
                {text: this.$t('settings.static.name'), value: 'first_name'},
                {text: this.$t('settings.static.email'), value: 'email'},
                {text: this.$t('settings.static.activated'), value: 'activated_at'},
                {text: this.$t('general.static.companies'), value: 'companies_count'},
                {value: 'actions', align: 'right', sortable: false},
            ];
        },

        queryParameters() {
            let {sortBy, sortDesc, page, itemsPerPage} = this.dataTableOptions;

            sortBy = _.first(sortBy);
            sortDesc = _.first(sortDesc);

            return [
                `paginate=${itemsPerPage}`,
                `page=${page}`,
                `order_by=${sortBy || 'id'}`,
                `order=${sortDesc ? 'desc' : 'asc'}`,
                ...(this.searchDebounced ? [
                    `search_columns=[${this.searchColumns.join(',')}]`,
                    `search_value=${this.searchDebounced}`,
                ] : [])
            ];
        }
    },

    watch: {
        queryParameters: {
            handler() {
                this.load();
            },
            deep: true
        },

        search: _.debounce(function () {
            this.searchDebounced = this.search;
        }, 500),
    },

    methods: {
        makeUser() {
            this.selectedUser = null;
            this.showUserDialog = true;
        },

        editUser(user) {
            this.selectedUser = user;
            this.showUserDialog = true;
        },

        confirmDeleteUser(user) {
            this.selectedUser = user;
            this.showConfirmDeleteDialog = true;
        },

        impersonate(user) {
            this.impersonatingUserId = user.id;

            axios.post(`/api/users/${user.id}/impersonate`)
                .then(async () => {
                    this.$root.showSnackbar(this.$t('auth.static.impersonateSuccessfull', {name: user.name}), 'success');

                    await this.$root.loadAuthenticatedUser(true);

                    this.$router.push({name: 'Home'});
                })
                .catch(() => {
                    this.$root.showSnackbar(this.$t('auth.static.impersonateFailed'), 'error');
                })
                .finally(() => {
                    this.impersonatingUserId = null;
                });
        },

        load() {
            this.loading = true;

            let parameters = [
                `with=[companies,activeCompanies]`,
                `count=[companies,activeCompanies]`,
                ...this.queryParameters,
            ];

            axios.get(`/api/users?${_.join(parameters, '&')}`)
                .then((response) => {
                    this.users = response.data.items;
                    this.totalUsers = response.data.total;
                })
                .catch(() => {

                })
                .finally(() => {
                    this.loading = false;
                });
        },
    }
}
</script>
