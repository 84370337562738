<template>

    <v-menu v-model="show" :close-on-content-click="false" :top="top" max-width="580" offset-y>
        <template v-slot:activator="{ on }">
            <v-text-field
                :disabled="disabled"
                :error-messages="errorMessages"
                :label="label"
                :value="formatDatetime"
                append-icon="mdi-calendar"
                dense
                outlined
                @change="parseManualInput"
                @click:clear="input(null)"
                @click:append="show = ! show"
            ></v-text-field>
        </template>

        <v-card>
            <v-row align="center" no-gutters>
                <v-col cols="6">
                    <v-date-picker
                        v-model="date"
                        :disabled="disabled"
                        :max="max"
                        :min="min"
                        class="elevation-0"
                        first-day-of-week="1"
                        show-week
                        @change="setValue"
                    ></v-date-picker>
                </v-col>

                <v-col cols="6">
                    <v-time-picker
                        v-model="time"
                        :disabled="disabled"
                        :use-seconds="useSeconds"
                        class="elevation-0"
                        format="24hr"
                        @input="setValue"
                    ></v-time-picker>
                </v-col>
            </v-row>

            <v-card-actions>
                <div class="flex-grow-1"></div>

                <v-btn color="primary" text @click="show = false">
                    OK
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>

</template>

<style>
.v-date-picker-title {
    height: 70px;
}
</style>

<script>
export default {
    props: {
        value: {
            default: null
        },

        label: {
            default: ''
        },

        errorMessages: {
            default: () => []
        },

        min: {
            default: null
        },

        max: {
            default: null
        },

        useSeconds: {
            default: false
        },

        hideDetails: {
            default: false
        },

        top: {
            default: false
        },

        disabled: {
            default: false
        },
    },

    watch: {
        value: {
            immediate: true,
            handler(value) {
                if (!value) return this.date = this.time = null;

                this.date = moment(value).format('YYYY-MM-DD');
                this.time = moment(value).format('HH:mm' + (this.useSeconds ? ':ss' : ''));
            }
        }
    },

    computed: {
        formatDatetime() {
            if (!this.value) return null;

            return moment(this.value).format('DD-MM-YYYY HH:mm' + (this.useSeconds ? ':ss' : ''));
        }
    },

    data() {
        return {
            show: false,

            date: null,
            time: null
        }
    },

    methods: {
        setValue() {
            this.input(
                (this.date ? this.date : moment().format('YYYY-MM-DD'))
                + ' '
                + (this.time ? this.time : ('00:00' + (this.useSeconds ? ':00' : '')))
            );
        },

        parseManualInput(value) {
            let dateTime = moment(value, 'DD-MM-YYYY HH:mm:ss');

            if (!dateTime.isValid()) return;

            this.date = dateTime.format('YYYY-MM-DD');
            this.time = dateTime.format('HH:mm:ss');

            this.setValue();
        },

        input(value) {
            this.$emit('input', moment(value).utc().format());
        }
    }
}
</script>
