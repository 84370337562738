import moment from 'moment';
import 'moment/locale/nl';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/es';

import 'core-js/features/number';
import 'core-js/features/math';
import _ from 'lodash';

import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';
import Axios from 'axios';
import CountriesList from 'countries-list';
import UAParser from 'ua-parser-js';

import Storage from '@/js/storage';
import Router from '@/js/router';
import Vuetify from '@/js/vuetify';
import i18n from '@/js/i18n';
import App from '@/js/App.vue';
import Keycloak from "keycloak-js";

require('@/img/background.jpg');
require('@/img/favicon.png');
require('@/img/google-play-badge.svg');
require('@/img/app-store-badge.svg');

window.axios = Axios;
window.axios.interceptors.request.use((config) => {
    if (!window.Vue.$gtm) return config;

    switch (config.method) {
        case 'post':
            window.Vue.$gtm.trackEvent({
                event: 'Create',
                category: 'request',
                action: `POST ${config.url}`,
            });
            break;
        case 'put':
            window.Vue.$gtm.trackEvent({
                event: 'Update',
                category: 'request',
                action: `PUT ${config.url}`,
            });
            break;
        case 'delete':
            window.Vue.$gtm.trackEvent({
                event: 'Delete',
                category: 'request',
                action: `DELETE ${config.url}`,
            });
            break;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

window.axios.interceptors.response.use((response) => response, (error) => {
    if (error.response.status === 401 && window.Vue.$router.currentRoute.meta.requiresAuth) {
        window.Vue.loadAuthenticatedUser(true);
    }

    return Promise.reject(error);
});

window.moment = moment;

window.storage = new Storage();

Vue.prototype.$_ = _;
Vue.prototype.$moment = window.moment;
Vue.prototype.$storage = window.storage;
Vue.prototype.$uaParser = UAParser();

if (process.env.VUE_APP_GTM_TAG) {
    Vue.use(VueGtm, {
        id: process.env.VUE_APP_GTM_TAG,
        enabled: true,
        vueRouter: Router,
        debug: process.env.NODE_ENV == 'development'
    });
}

Vue.config.productionTip = false;


let initVue = () => {
    window.Vue = new Vue({
        router: Router,
        vuetify: Vuetify,
        i18n: i18n,

        render: h => h(App, { props: { keycloak: keycloak } }),

        data: {
            initializing: false,
            loggingOut: false,

            hasCsrfCookie: false,

            user: null,
            activeCompany: null,

            loadingAuthenticatedUser: false,

            snackbar: {
                text: null,
                type: null,
            },

            countries: CountriesList.countries,
        },

        watch: {
            activeCompany(company) {
                if (this.user && this.user.is_impersonated) return;

                storage.set(storage.ACTIVE_COMPANY_ID, company ? company.id : null);
            },
        },

        computed: {
            routeRequiresAuth() {
                return this.$route.meta.requiresAuth;
            },

            routeIsGuest() {
                return this.$route.meta.guest;
            },

            modules() {
                return [
                    {
                        slug: 'hoofcare',
                        name: this.$t('hoofcare.static.hoofcare'),
                        route: 'Hoofcare',
                        icon: require('@/img/modules/cow.svg'),
                        outlinedIcon: null,
                    },
                    {
                        slug: 'cleaning_pigs',
                        name: this.$t('cleaningAndDisinfection.static.cleaningAndDisinfectionPigs'),
                        route: 'CleaningPigs',
                        icon: require('@/img/modules/pig.svg'),
                        outlinedIcon: require('@/img/modules/pig-outlined.svg'),
                    },
                    {
                        slug: 'cleaning_poultry',
                        name: this.$t('cleaningAndDisinfection.static.cleaningAndDisinfectionPoultry'),
                        route: 'CleaningPoultry',
                        icon: require('@/img/modules/poultry.svg'),
                        outlinedIcon: require('@/img/modules/poultry-outlined.svg'),
                    },
                    {
                        slug: 'water_solutions',
                        name: this.$t('waterSolutions.static.waterSolutions'),
                        route: 'WaterSolutions',
                        icon: require('@/img/modules/water-solutions.svg'),
                        outlinedIcon: null,
                    },
                    {
                        slug: 'high_performance_box',
                        name: this.$t('highPerformanceBox.static.highPerformanceBox'),
                        route: '',
                        icon: require('@/img/modules/high-performance-box.svg'),
                        outlinedIcon: null,
                    },
                    {
                        slug: 'apc',
                        name: this.$t('apc.static.apc'),
                        route: '',
                        icon: require('@/img/modules/high-performance-box.svg'),
                        outlinedIcon: null,
                    },
                ];
            },

            companies() {
                if (!this.user) return [];

                return this.user.companies;
            },

            activeCompanies() {
                if (this.user && this.user.isAdmin) return this.companies;

                return _.filter(this.companies, (company) => {
                    return company.isActive;
                });
            },

            parentCompanies() {
                let parents = _.filter(this.activeCompanies, (company) => {
                    return company.parent_id == null
                        || !_.find(this.activeCompanies, {id: company.parent_id});
                });

                return _.map(parents, (parent) => {
                    return {
                        ...parent,
                        children: _.filter(this.activeCompanies, ['parent_id', parent.id])
                    }
                });
            },
        },

        async created() {
            this.initializing = true;

            await this.loadAuthenticatedUser();

            if (this.routeIsGuest && this.user) {
                this.$router.replace({name: 'Home'});
            }

            if (this.routeRequiresAuth && !this.user) {
                await this.loadAuthenticatedUser();
            }

            this.initializing = false;
        },

        methods: {

            async loadAuthenticatedUser(refresh = false) {
                if (this.user && !refresh) return true;

                this.loadingAuthenticatedUser = true;

                return axios.get('/api/users/me?with=[companies.address,companies.setting,companies.modules]&appends=[isAdmin,availableModules]')
                    .then(async (response) => {
                        this.user = response.data;

                        let activeCompanyId = this.user.is_impersonated
                            ? (_.first(this.user.companies) ?? {}).id
                            : await storage.get(storage.ACTIVE_COMPANY_ID);

                        if (!_.isNull(activeCompanyId) && !_.isUndefined(activeCompanyId)) {
                            this.setActiveCompany(_.find(this.user.companies, {id: activeCompanyId}));
                        } else {
                            this.setActiveCompany(_.head(this.parentCompanies))
                        }

                        this.setLocale(this.user.language_code);
                    })
                    .catch(async () => {
                        this.user = null;

                        if (this.$router.currentRoute.meta.requiresAuth) {
                            keycloak.login({ redirectUri: window.location.href });
                        }
                    })
                    .finally(() => {
                        this.loadingAuthenticatedUser = false;
                    });
            },

            setActiveCompany(company) {
                this.activeCompany = company;
            },

            showSnackbar(text, type) {
                this.snackbar.text = text;
                this.snackbar.type = type;
            },

            setLocale(languageCode) {
                this.$i18n.locale = languageCode || 'en';

                window.moment.locale(this.$i18n.locale);
            },

            async logout() {
                if (this.user.is_impersonated) {
                    document.cookie = "impersonated_user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    await this.$root.loadAuthenticatedUser(true);

                    this.$router.push({name: 'Home'});
                } else {
                    this.loggingOut = true;
                    keycloak.logout({redirectUri: window.location.origin})
                }
            },
        }
    }).$mount('#app');
}

let initOptions = {
    ...window.Config.keycloak,
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`
}

let keycloak = new Keycloak(initOptions);

window.keycloak = keycloak;
keycloak.init(initOptions).then((auth) => {
   if (auth) {
       window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + keycloak.token;
       console.log("Authenticated");
   }

    initVue();

    //Token Refresh
    setInterval(() => {
        keycloak.updateToken(70).then((refreshed) => {
            if (refreshed) {
                window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + keycloak.token;
                console.log('Token refreshed' + refreshed);
            } else {
                console.warn('Token not refreshed, valid for '
                    + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
            }
        }).catch(() => {
            console.error('Failed to refresh token');
        });
    }, 6000)
}).catch((err) => {
    console.error("Authenticated Failed", err);
});

Router.beforeEach(async (to, from, next) => {
    document.title = `HyCheck | ${to.meta.title}`;

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!Router.app.user) {
            if (keycloak.authenticated === false) {
                keycloak.login({ redirectUri: window.location.href });
            }
            const interval = setInterval(() => {
                clearInterval(interval);
                next();
            }, 100)
            return;
        } else {
            next();
        }
    }

    if (to.matched.some((record) => record.meta.guest)) {
        if (Router.app.user) {
            next({name: 'Home'});
        } else {
            next();
        }
    } else {
        next();
    }
});
