<template>

    <v-dialog
        :value="value"
        max-width="500"
        @input="$emit('input', $event)"
    >
        <v-card>
            <v-card-title>
                {{ exists ? $t('settings.static.editUser') : $t('settings.static.createUser') }}
            </v-card-title>

            <v-card-text v-if="data" class="pb-0">
                <v-text-field
                    v-model="data.email"
                    :error-messages="validationErrors['email']"
                    :label="$t('settings.static.email')"
                    :loading="findingUserWithEmail"
                    dense
                    outlined
                ></v-text-field>

                <template v-if="exists || (emailChecked && ! emailExists)">
                    <v-text-field
                        v-model="data.first_name"
                        :error-messages="validationErrors['first_name']"
                        :label="$t('general.static.firstName')"
                        dense
                        outlined
                    ></v-text-field>

                    <v-text-field
                        v-model="data.last_name"
                        :error-messages="validationErrors['last_name']"
                        :label="$t('general.static.lastName')"
                        dense
                        outlined
                    ></v-text-field>

                    <language-select
                        v-model="data.language_code"
                        :validation-errors="validationErrors['language_code']"
                        text
                    ></language-select>
                </template>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions v-if="data" class="px-6 py-5">
                <v-spacer></v-spacer>

                <v-btn text @click="$emit('input', false)">
                    {{ $t('general.actions.cancel') }}
                </v-btn>

                <v-btn
                    :loading="saving"
                    color="primary"
                    @click="save"
                >
                    {{ $t('general.actions.save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>
import _ from 'lodash';
import LanguageSelect from '@/js/components/LanguageSelect';

export default {
    components: {LanguageSelect},

    props: {
        value: Boolean,
        user: Object,
    },

    data() {
        return {
            data: null,

            saving: false,

            validationErrors: {},

            userForEmail: null,
            emailChecked: false,
            findingUserWithEmail: false,
        }
    },

    computed: {
        activeCompany() {
            return this.$root.activeCompany;
        },

        exists() {
            return !this.data || !!this.data.id;
        },

        emailExists() {
            return !!this.userForEmail;
        },
    },

    watch: {
        value() {
            this.data = {
                company_ids: this.activeCompany ? [this.activeCompany.id] : [],
                language_code: 'en',
                first_name: null,
                last_name: null,
                email: null,
                ..._.cloneDeep(this.user)
            };

            this.validationErrors = {};
        },

        'data.email': _.debounce(function () {
            if (!this.data.email) return;

            this.findUserWithEmail(this.data.email);
        }, 500),
    },

    methods: {
        save() {
            this.saving = true;

            let request = this.exists
                ? this.update()
                : this.create();

            request.then((response) => {
                let message = this.exists
                    ? this.$t('settings.static.userUpdated')
                    : this.$t('settings.static.userCreated');

                this.$root.showSnackbar(message, 'success');

                this.$emit(this.exists ? 'updated' : 'created');

                this.$emit('input', false);
            }).catch((error) => {
                if (error.response.status == 422) {
                    this.validationErrors = error.response.data.errors;
                }
            }).finally(() => {
                this.saving = false;
            });
        },

        findUserWithEmail(email) {
            this.findingUserWithEmail = true;

            axios.get(`/api/users?find_by_email=${email}`)
                .then((response) => {
                    this.userForEmail = _.first(response.data.items);
                })
                .finally(() => {
                    this.emailChecked = true;
                    this.findingUserWithEmail = false;
                });
        },

        create() {
            return axios.post(`/api/users`, {
                attachUserToCompanies: this.emailExists,
                ...this.data
            });
        },

        update() {
            return axios.put(`/api/users/${this.data.id}`, this.data);
        }
    }
}
</script>
