<template>

    <v-dialog
        :value="value"
        max-width="900"
        @input="$emit('input', $event)"
    >
        <v-card>
            <v-card-title>
                {{ exists ? $t('settings.static.editCompany') : $t('settings.static.createCompany') }}

                <v-spacer></v-spacer>

                <small v-if="data && data.updated_at" class="caption grey--text font-weight-regular">
                    {{
                        $t('general.static.lastUpdatedAt', {date: $moment(data.updated_at).format('DD-MM-YYYY HH:mm')})
                    }}
                </small>
            </v-card-title>

            <v-card-text v-if="data" class="pt-3 pb-0">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-row dense>
                            <v-col class="pb-0" cols="12" sm="8">
                                <v-text-field
                                    v-model="data.name"
                                    :error-messages="validationErrors['name']"
                                    :label="$t('settings.static.name')"
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                            <v-col class="pb-0" cols="12" sm="4">
                                <v-text-field
                                    v-model="data.reference"
                                    :error-messages="validationErrors['reference']"
                                    :label="$t('settings.static.reference')"
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="pb-0" cols="12">
                                <v-text-field
                                    v-model="data.email"
                                    :error-messages="validationErrors['email']"
                                    :label="$t('settings.static.email')"
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="pb-0" cols="12">
                                <v-text-field
                                    v-model="data.phone"
                                    :error-messages="validationErrors['phone']"
                                    :label="$t('settings.static.phone')"
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="pb-0" cols="12">
                                <company-select
                                    v-if="value"
                                    v-model="data.parent_id"
                                    :excluded="[data.id]"
                                    :label="$t('settings.static.parentCompany')"
                                    :validation-errors="validationErrors['parent_id']"
                                    exclude-children
                                ></company-select>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="pb-0" cols="12">
                                <user-select
                                    v-model="data.user_ids"
                                    :validation-errors="validationErrors['user_ids']"
                                    multiple
                                ></user-select>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-row dense>
                            <v-col :cols="this.$vuetify.breakpoint.xsOnly ? 12 : null" class="pb-0 shrink">
                                <v-btn
                                    :color="data.is_activated ? 'primary' : 'grey'"
                                    block
                                    outlined
                                    style="height:40px"
                                    @click="data.is_activated = ! data.is_activated"
                                >
                                    <v-icon left>
                                        {{
                                            data.is_activated ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'
                                        }}
                                    </v-icon>
                                    {{ $t('settings.static.activated') }}
                                </v-btn>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.xsOnly ? 12 : null" class="pb-0 grow">
                                <date-picker
                                    v-model="data.trial_ends_at"
                                    :disabled="data.is_activated"
                                    :label="$t('settings.static.trialUntil', {date: ''})"
                                ></date-picker>
                            </v-col>
                        </v-row>

                        <v-row dense>
                            <v-col class="pb-0" cols="12" sm="8">
                                <v-text-field
                                    v-model="data.address.street"
                                    :error-messages="validationErrors['address.street']"
                                    :label="$t('settings.static.address')"
                                    color="primary"
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                            <v-col class="pb-0" cols="12" sm="4">
                                <v-text-field
                                    v-model="data.address.street_number"
                                    :error-messages="validationErrors['address.street_number']"
                                    :label="$t('settings.static.addressNumber')"
                                    color="primary"
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row dense>
                            <v-col class="pb-0" cols="12" sm="4">
                                <v-text-field
                                    v-model="data.address.zip_code"
                                    :error-messages="validationErrors['address.zip_code']"
                                    :label="$t('settings.static.zipCode')"
                                    color="primary"
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                            <v-col class="pb-0" cols="12" sm="8">
                                <v-text-field
                                    v-model="data.address.city"
                                    :error-messages="validationErrors['address.city']"
                                    :label="$t('settings.static.city')"
                                    color="primary"
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="pb-0" cols="12">
                                <country-select
                                    v-model="data.address.country"
                                    :error-messages="validationErrors['country']"
                                ></country-select>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="pb-0" cols="12">
                                <module-select
                                    v-model="data.module_slugs"
                                    :validation-errors="validationErrors['module_slugs']"
                                    multiple
                                ></module-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pb-0" cols="12">
                                <v-text-field
                                    v-model="data.hycare_id"
                                    label="hycare ID"
                                    color="primary"
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pb-0" cols="12">
                                <v-text-field
                                    v-model="data.hycare_location_id"
                                    label="hycare location ID"
                                    color="primary"
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pb-0" cols="12">
                                <v-text-field
                                    v-model="data.bunkers"
                                    label="HBP bunkers"
                                    color="primary"
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                            <v-col class="pb-0" cols="12">
                                <v-text-field
                                    v-model="data.uuid_filter_prefix"
                                    label="HBP Transmitter prefix filter"
                                    color="primary"
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pb-0" cols="12">
                                <v-text-field
                                    v-model="data.UBN"
                                    label="UBN"
                                    color="primary"
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions v-if="data" class="px-6 py-5">
                <v-text-field
                    v-model="boxId"
                    label="Box ID"
                    color="primary"
                    dense
                    outlined
                ></v-text-field>
                <v-file-input accept=".csv" v-model="file" @change="fileChanged">
                    Import CSV (HPB)
                </v-file-input>
                <v-btn :disabled="invalidFile" text @click="uploadCSV">
                    Import CSV (HBP)
                </v-btn>
                <v-spacer></v-spacer>

                <v-btn text @click="$emit('input', false)">
                    {{ $t('general.actions.cancel') }}
                </v-btn>

                <v-btn
                    :loading="saving"
                    color="primary"
                    @click="save"
                >
                    {{ exists ? $t('general.actions.save') : $t('general.actions.create') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>
import LanguageSelect from '@/js/components/LanguageSelect';
import RoleSelect from '@/js/components/RoleSelect';
import CompanySelect from '@/js/components/CompanySelect';
import UserSelect from '@/js/components/UserSelect';
import ModuleSelect from '@/js/components/ModuleSelect';
import DatePicker from '@/js/components/DatePicker';
import CountrySelect from '@/js/components/CountrySelect';

export default {
    components: {DatePicker, LanguageSelect, RoleSelect, CompanySelect, UserSelect, ModuleSelect, CountrySelect},

    props: {
        value: Boolean,
        company: Object,
    },

    data() {
        return {
            data: null,

            saving: false,

            invalidFile: true,

            validationErrors: {},
        }
    },

    computed: {
        exists() {
            return !this.data || !!this.data.id;
        }
    },

    watch: {
        value() {
            this.data = {
                parent_id: null,
                user_ids: [],
                module_slugs: [],
                is_activated: false,
                trial_ends_at: null,
                reference: null,
                name: null,
                email: null,
                phone: null,
                address: {
                    street: null,
                    street_number: null,
                    zip_code: null,
                    city: null,
                    country: null,
                },
                ..._.cloneDeep(this.company)
            };

            if (this.company && this.company.users) {
                this.data.user_ids = _.map(this.company.users, 'id');
            }

            if (this.company && this.company.modules) {
                this.data.module_slugs = _.map(this.company.modules, 'slug');
            }

            this.validationErrors = {};
        }
    },

    methods: {
        save() {
            this.saving = true;

            let request = this.exists
                ? this.update()
                : this.create();

            request.then((response) => {
                let message = this.exists
                    ? this.$t('settings.static.companyUpdated')
                    : this.$t('settings.static.companyCreated');

                this.$root.showSnackbar(message, 'success');

                this.$emit(this.exists ? 'updated' : 'created');

                this.$emit('input', false);
            }).catch((error) => {
                if (error.response.status == 422) {
                    this.validationErrors = error.response.data.errors;
                }
            }).finally(() => {
                this.saving = false;
            });
        },

        fileChanged(e) {
            if (this.file) {
                this.invalidFile = false;
            } else {
                this.invalidFile = true;
            }
        },

        uploadCSV() {
            let formData = new FormData();
            formData.append('id', this.boxId);
            formData.append('list', this.file);
            formData.append('company_id', this.data.id);

            axios.post(`/api/boxes/assign-csv`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-Company': this.data.id
                }
            }).then((response) => {
                this.file = null;
                this.invalidFile = true;
                alert('CSV imported');
            }).catch((error) => {
                this.file = null;
                this.invalidFile = true;
                alert('Error importing CSV');
            });
        },

        create() {
            return axios.post(`/api/companies`, this.data);
        },

        update() {
            return axios.put(`/api/companies/${this.data.id}`, this.data);
        }
    }
}
</script>
