<template>

    <v-container :fluid="$vuetify.breakpoint.mdAndDown">
        <v-row class="justify-space-between align-center">
            <v-col class="display-1 primary--text font-weight-medium d-flex">
                {{ $t('hoofcare.static.hoofcare') }}
            </v-col>

            <v-col class="shrink d-flex">
                <v-btn
                    color="primary"
                    text
                    @click="$router.push({ name: 'Home' })"
                >
                    <v-icon left>
                        mdi-view-module
                    </v-icon>
                    {{ $t('general.actions.home') }}
                </v-btn>
            </v-col>
        </v-row>

        <v-row class="align-stretch">
            <v-col cols="12" md="6">
                <v-card class="fill-height">
                    <v-overlay :value="loading" absolute color="white"></v-overlay>

                    <v-data-table
                        :custom-sort="tableSort"
                        :headers="headers"
                        :items="companiesWithStatus"
                        :loading="loading"
                        :options.sync="dataTableOptions"
                        disable-filtering
                        disable-pagination
                        hide-default-footer
                        must-sort
                    >
                        <template v-slot:item.hoofcareStatistics.currentScore="{ item }">
                            <v-icon :color="item.color">
                                mdi-circle
                            </v-icon>
                            <span v-if="item.hoofcareStatistics.currentScore" class="caption">
                                {{ item.hoofcareStatistics.currentScore }}%
                            </span>
                        </template>

                        <template v-slot:item.actions="{ item }">
                            <v-btn
                                color="primary"
                                small
                                text
                                @click="$router.push({ name: 'HoofcareDetails', params: { companyId: item.id } })"
                            >
                                <v-icon left>
                                    mdi-arrow-right
                                </v-icon>
                                {{ $t('general.actions.viewDetails') }}
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>

            <v-col cols="12" md="6">
                <v-card class="fill-height">
                    <v-overlay :value="loading" absolute color="white"></v-overlay>

                    <v-card-title class="subtitle-1">
                        {{ $t('hoofcare.static.mortellaroFreePercentage') }}
                    </v-card-title>

                    <v-card-text>
                        <bar-chart
                            :chart-data="{ labels: chartLabels, datasets: chartDatasets }"
                            :height="300"
                            :max="100"
                            :min="0"
                            :show-dates="false"
                            :show-legend="false"
                            chart-id="hoofcare-companies-bar-chart"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
import BarChart from '@/js/components/charts/BarChart';

import CheckpointStatusMixin from '@/js/mixins/checkpointStatus';
import TableMixin from '@/js/mixins/table';

export default {
    components: {BarChart},

    mixins: [CheckpointStatusMixin, TableMixin],

    data() {
        return {
            loading: false,

            dataTableOptions: {
                sortBy: ['name'],
                sortDesc: [false],
            },

            companies: [],
        }
    },

    computed: {
        headers() {
            return [
                {text: this.$t('general.static.location'), value: 'name'},
                {text: this.$t('hoofcare.static.mortellaroFreePercentage'), value: 'hoofcareStatistics.currentScore'},
                {value: 'actions', align: 'right', sortable: false},
            ];
        },

        queryParameters() {
            let parameters = [
                `appends=[hoofcareStatistics]`,
                `where_in=id|${_.map(this.user.companies, 'id')}`
            ];

            return parameters;
        },

        companiesWithStatus() {
            return _.map(this.companies, (company) => {
                return {
                    status: this.getStatusForScore(company.hoofcareStatistics.currentScore),
                    color: this.colorForScore(company.hoofcareStatistics.currentScore),
                    ...company
                }
            });
        },

        chartLabels() {
            return _.map(this.companies, (company) => {
                return company.name;
            });
        },

        chartDatasets() {
            return [{
                backgroundColor: _.map(this.companies, (company) => {
                    return this.colorForScore(company.hoofcareStatistics.currentScore);
                }),
                data: _.map(this.companies, (company) => {
                    return company.hoofcareStatistics.currentScore;
                }),
            }];
        },

        user() {
            return this.$root.user;
        },

        module() {
            return _.find(this.$root.modules, {slug: 'hoofcare'});
        }
    },

    mounted() {
        this.load();
    },

    methods: {
        load() {
            if (this.loading) return;

            this.loading = true;

            axios.get(`/api/companies?${_.join(this.queryParameters, '&')}`)
                .then((response) => {
                    this.companies = response.data.items;

                    if (this.companies.length == 1) {
                        this.$router.replace({
                            name: 'HoofcareDetails',
                            params: {companyId: _.first(this.companies).id}
                        });
                    }
                })
                .catch(() => {

                })
                .finally(() => {
                    this.loading = false;
                });
        },

        colorForScore(score) {
            switch (true) {
                case score === null:
                    return 'grey';
                    break;
                case score < 25:
                    return this.$vuetify.theme.currentTheme.warning;
                    break;
                case score < 50:
                    return this.$vuetify.theme.currentTheme.tertiary;
                    break;
                case score < 75:
                    return this.$vuetify.theme.currentTheme.info;
                    break;
                default:
                    return this.$vuetify.theme.currentTheme.success;
                    break;
            }
        },
    }
}
</script>
