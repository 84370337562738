<template>

    <v-container v-if="activeCompany" :fluid="$vuetify.breakpoint.mdAndDown">
        <v-row>
            <v-col class="display-1 primary--text font-weight-medium">
                {{ $t('general.static.welcomeBack', {name: user.first_name}) }}
            </v-col>
        </v-row>

        <v-row class="align-stretch">
            <v-col
                v-for="module in activatedModules"
                :key="module.slug"
                cols="12"
                md="4"
                sm="6"
                xl="3"
            >
                <v-card
                    class="overflow-hidden fill-height"
                    color="primary"
                    dark
                    min-height="250"
                >
                    <v-card-text
                        class="fill-height d-flex flex-column align-center justify-space-between"
                    >
                        <div class="d-flex pt-3">
                            <v-img :src="module.icon" contain height="40" width="40"></v-img>
                        </div>
                        <div class="d-flex py-8 title white--text text-center">
                            {{ module.name }}
                        </div>
                        <div class="d-flex pb-3">
                            <v-btn v-if="module.route"
                                   :to="{ name: module.route }"
                                   color="white"
                                   outlined>

                                {{ $t('general.actions.viewDetails') }}
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
export default {
    data() {
        return {}
    },

    computed: {
        activeCompany() {
            return this.$root.activeCompany;
        },

        activatedModules() {
            if (!this.user) return [];

            return _.map(this.user.availableModules, (module) => {
                return _.find(this.$root.modules, {slug: module.slug});
            });
        },

        user() {
            return this.$root.user;
        }
    },

    mounted() {

    },

    methods: {}
}
</script>
return [];
