<template>

    <v-card v-if="user">
        <v-card-subtitle class="subtitle-1 black--text">
            {{ $t('settings.static.changePassword') }}
        </v-card-subtitle>

        <v-card-text class="pb-0">
            <div class="pb-3">
                {{ $t('settings.static.changePasswordDescription') }}
            </div>

            <v-text-field
                id="password"
                v-model="user.password"
                :error-messages="validationErrors['password']"
                :label="$t('auth.static.password')"
                :type="passwordInputType"
                append-icon="mdi-eye"
                dense
                outlined
                @click:append="passwordInputType = passwordInputType == 'password' ? 'text' : 'password'"
            ></v-text-field>

            <v-text-field
                id="password-confirmation"
                v-model="user.password_confirmation"
                :error-messages="validationErrors['password_confirmation']"
                :label="$t('auth.static.confirmPassword')"
                :type="passwordConfirmationInputType"
                append-icon="mdi-eye"
                dense
                outlined
                @click:append="passwordConfirmationInputType = passwordConfirmationInputType == 'password' ? 'text' : 'password'"
            ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-4">
            <v-spacer></v-spacer>

            <v-btn :loading="saving" color="primary" @click="save">
                {{ $t('settings.actions.update') }}
            </v-btn>
        </v-card-actions>
    </v-card>

</template>

<script>
export default {
    data() {
        return {
            passwordInputType: 'password',
            passwordConfirmationInputType: 'password',

            user: {
                id: null,
                password: null,
                password_confirmation: null,
            },

            validationErrors: {},

            saving: false,
        }
    },

    watch: {
        '$root.user': {
            handler() {
                this.user = {
                    ...this.user,
                    ...this.$root.user,
                }
            },
            immediate: true
        }
    },

    methods: {
        save() {
            this.saving = true;

            axios.put(`/api/users/${this.user.id}`, this.user)
                .then(() => {
                    this.user.password = null;
                    this.user.password_confirmation = null;

                    this.$root.loadAuthenticatedUser(true);

                    this.$root.showSnackbar(this.$t('settings.static.userSaved'), 'success');
                })
                .catch((error) => {
                    if (error.response.status == 422) {
                        this.validationErrors = error.response.data.errors;
                    }
                })
                .finally(() => {
                    this.saving = false;
                });
        }
    }
}
</script>
