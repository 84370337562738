var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-overlay',{attrs:{"value":_vm.loading || _vm.impersonatingUserId,"absolute":"","color":"white"}}),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$t('general.static.search'),"append-icon":"mdi-magnify","color":"primary","dense":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider'),_c('v-data-table',{attrs:{"footer-props":{ itemsPerPageOptions: [10, 25, 50] },"headers":_vm.headers,"items":_vm.users,"loading":_vm.loading,"options":_vm.dataTableOptions,"server-items-length":_vm.totalUsers},on:{"update:options":function($event){_vm.dataTableOptions=$event}},scopedSlots:_vm._u([{key:"item.first_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.activated_at",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.activated_at ? 'success' : 'grey'}},[_vm._v(" "+_vm._s(item.activated_at ? 'mdi-check' : '')+" ")]),(item.activated_at)?_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.$moment(item.activated_at).format('DD-MM-YYYY'))+" ")]):_vm._e()]}},{key:"item.companies_count",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.active_companies_count == item.companies_count ? 'success' : 'warning',"small":""}},[_vm._v(" "+_vm._s(item.active_companies_count)+" / "+_vm._s(item.companies_count)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[(! _vm.authenticatedUser.is_impersonated)?_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":item.id == _vm.authenticatedUser.id,"loading":_vm.impersonatingUserId == item.id,"color":"primary","icon":"","small":""},on:{"click":function($event){return _vm.impersonate(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-shield-account ")])],1):_vm._e(),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editUser(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"disabled":item.id == _vm.authenticatedUser.id,"small":""},on:{"click":function($event){return _vm.confirmDeleteUser(item)}}},[_vm._v(" mdi-delete ")])],1)]}}])}),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.makeUser}},[_vm._v(" "+_vm._s(_vm.$t('settings.actions.addUser'))+" ")])],1),_c('user-dialog',{attrs:{"user":_vm.selectedUser},on:{"created":_vm.load,"updated":function($event){_vm.$root.loadAuthenticatedUser(true);_vm.load()}},model:{value:(_vm.showUserDialog),callback:function ($$v) {_vm.showUserDialog=$$v},expression:"showUserDialog"}}),_c('confirm-delete-dialog',{attrs:{"user":_vm.selectedUser},on:{"deleted":function($event){_vm.$root.loadAuthenticatedUser(true);_vm.load()}},model:{value:(_vm.showConfirmDeleteDialog),callback:function ($$v) {_vm.showConfirmDeleteDialog=$$v},expression:"showConfirmDeleteDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }