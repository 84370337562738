<template>

    <v-autocomplete
        :error-messages="validationErrors"
        :items="sortedAnimals"
        :label="label || $t('general.static.animals')"
        :loading="loading"
        :multiple="multiple"
        :value="value"
        dense
        hide-selected
        item-text="code"
        item-value="id"
        outlined
        @input="$emit('input', $event)"
    >
        <template v-slot:selection="data">
            <v-chip-group column>
                <v-chip
                    :input-value="data.selected"
                    close
                    color="primary"
                    small
                    v-bind="data.attrs"
                    @click="data.select"
                    @click:close="deselect(data.item)"
                >
                    {{ data.item.code }}

                    <span v-if="data.item.company" class="ml-1 caption grey--text text--lighten-2">
                        {{ $_.truncate(data.item.company.name, {length: 12}) }}
                    </span>
                </v-chip>
            </v-chip-group>
        </template>

        <template v-slot:item="{ item }">
            <v-list-item-content>
                <v-list-item-title>
                    {{ item.code }}
                </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action-text v-if="item.company">
                {{ item.company.name }}
            </v-list-item-action-text>
        </template>
    </v-autocomplete>

</template>

<script>
export default {
    props: {
        value: {},
        validationErrors: Array,
        multiple: Boolean,
        label: String,
        excluded: Array,
    },

    data() {
        return {
            animals: [],

            loading: false,
        }
    },

    computed: {
        sortedAnimals() {
            return _.sortBy(this.filteredAnimals, ['company.name', 'code']);
        },

        filteredAnimals() {
            return _.filter(this.animals, (animal) => {
                if (!this.excluded) return true;

                return _.indexOf(this.excluded, animal.id) < 0;
            });
        },
    },

    mounted() {
        this.load();
    },

    methods: {
        load() {
            this.loading = true;

            axios.get(`/api/animals?with=[company]&order_by=code&order=asc`)
                .then((response) => {
                    this.animals = response.data.items;
                })
                .catch(() => {
                    this.$root.showSnackbar(this.$t('settings.static.loadAnimalsFailed'), 'error');
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        deselect(animal) {
            this.$emit(
                'input',
                this.multiple
                    ? _.filter(this.value, (id) => id != animal.id)
                    : null
            );
        },
    },
}
</script>
