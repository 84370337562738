<template>

    <div>
        <v-dialog
            :value="value"
            max-width="700"
            @input="$emit('input', $event)"
        >
            <v-card v-if="value">
                <v-card-title>
                    {{ $t('hoofcare.static.hoofcare') }}
                </v-card-title>

                <v-data-table
                    :expanded.sync="expandedItems"
                    :footer-props="{ itemsPerPageOptions: [10, 25, 50] }"
                    :headers="headers"
                    :items="mortellaroScores"
                    :loading="loading"
                    :options.sync="dataTableOptions"
                    :server-items-length="totalMortellaroScores"
                    show-expand
                >
                    <template v-slot:item.saved_at="{ item }">
                        {{ item.saved_at ? $moment(item.saved_at).format('DD-MM-YYYY HH:mm') : '' }}
                    </template>

                    <template v-slot:item.hoof_position="{ item }">
                        <v-icon left small>
                            {{ hoofPositionIcons[item.hoof_position] }}
                        </v-icon>
                        {{ hoofPositionLabels[item.hoof_position] }}
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-icon
                            class="mr-2"
                            small
                            @click="editMortellaroScore(item)"
                        >
                            mdi-pencil
                        </v-icon>
                        <v-icon
                            small
                            @click="confirmDeleteMortellaroScore(item)"
                        >
                            mdi-delete
                        </v-icon>
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="px-0">
                            <v-card :href="item.downloadUrls" color="grey lighten-3" flat height="300" width="700">
                                <v-img
                                    :src="item.base64Images ? `data:image/png;base64, ${item.base64Images}` : item.downloadUrls"
                                    class="fill-height"
                                    contain
                                ></v-img>
                            </v-card>
                        </td>
                    </template>
                </v-data-table>

                <v-divider></v-divider>

                <v-card-actions class="px-6 py-5">
                    <v-spacer></v-spacer>

                    <v-btn text @click="$emit('input', false)">
                        {{ $t('general.actions.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <mortellaro-score-dialog
            v-model="showMortellaroScoreDialog"
            :mortellaro-score="selectedMortellaroScore"
            @created="load"
            @updated="load"
        ></mortellaro-score-dialog>

        <confirm-delete-mortellaro-score-dialog
            v-model="showConfirmDeleteDialog"
            :mortellaro-score="selectedMortellaroScore"
            @deleted="load()"
        ></confirm-delete-mortellaro-score-dialog>
    </div>

</template>

<script>
import HoofPositionMixin from '@/js/mixins/hoofPosition';

import CompanySelect from '@/js/components/CompanySelect';
import MortellaroScoreDialog from './MortellaroScoreDialog';
import ConfirmDeleteMortellaroScoreDialog from './ConfirmDeleteMortellaroScoreDialog';

export default {
    components: {CompanySelect, MortellaroScoreDialog, ConfirmDeleteMortellaroScoreDialog},

    mixins: [HoofPositionMixin],

    props: {
        value: Boolean,
        animal: Object,
    },

    data() {
        return {
            mortellaroScores: [],
            totalMortellaroScores: 0,

            expandedItems: [],

            selectedMortellaroScore: null,
            showMortellaroScoreDialog: false,
            showConfirmDeleteDialog: false,

            loading: false,
            saving: false,

            dataTableOptions: {
                sortBy: ['saved_at'],
                sortDesc: [true],
                multiSort: false,
                page: 1,
                itemsPerPage: 25
            },
        }
    },

    computed: {
        headers() {
            return [
                {text: this.$t('hoofcare.static.hoof'), value: 'hoof_position'},
                {text: this.$t('hoofcare.static.score'), value: 'score'},
                {text: this.$t('general.static.createdAt'), value: 'saved_at'},
                {value: 'actions', align: 'right', sortable: false},
                {value: 'data-table-expand', align: 'right', sortable: false},
            ];
        },

        activeCompany() {
            return this.$root.activeCompany;
        },

        queryParameters() {
            let {sortBy, sortDesc, page, itemsPerPage} = this.dataTableOptions;

            sortBy = _.first(sortBy);
            sortDesc = _.first(sortDesc);

            return [
                `paginate=${itemsPerPage}`,
                `page=${page}`,
                `order_by=${sortBy || 'id'}`,
                `order=${sortDesc ? 'desc' : 'asc'}`,
            ];
        },
    },

    watch: {
        queryParameters: {
            handler() {
                this.load();
            },
            deep: true
        },

        value() {
            this.mortellaroScores = [];
            this.totalMortellaroScores = 0;
            this.validationErrors = {};

            if (this.value) {
                this.load();
            }
        },
    },

    methods: {
        confirmDeleteMortellaroScore(mortellaroScore) {
            this.selectedMortellaroScore = mortellaroScore;
            this.showConfirmDeleteDialog = true;
        },

        load() {
            if (this.loading) return;

            this.loading = true;

            let parameters = [
                `where=[animal_id|=|${this.animal.id}]`,
                `appends=[base64Images,downloadUrls]`,
                ...this.queryParameters,
            ];

            axios.get(`/api/mortellaro-scores?${_.join(parameters, '&')}`)
                .then((response) => {
                    this.mortellaroScores = response.data.items;
                    this.totalMortellaroScores = response.data.total;

                    this.expandedItems = [];
                })
                .catch(() => {

                })
                .finally(() => {
                    this.loading = false;
                })
        },

        editMortellaroScore(mortellaroScore) {
            this.selectedMortellaroScore = mortellaroScore;
            this.showMortellaroScoreDialog = true;
        },
    }
}
</script>
