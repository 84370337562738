<template>

    <v-container :fluid="$vuetify.breakpoint.mdAndDown">
        <v-row class="justify-space-between align-center">
            <v-col class="display-1 primary--text font-weight-medium">
                {{ $t('hoofcare.static.hoofcare') }}

                <v-fade-transition mode="out-in">
                    <v-progress-circular
                        v-if="loading"
                        class="ml-3"
                        indeterminate
                        size="32"
                    ></v-progress-circular>
                    <small v-else-if="company" class="secondary--text text--lighten-2 font-weight-regular pt-1 mb-n2">
                        - {{ company.name }}
                    </small>
                </v-fade-transition>
            </v-col>

            <v-fade-transition>
                <v-col v-if="company && ! company.setting.cows_count" class="shrink">
                    <v-alert
                        border="left"
                        class="mb-0 warning--text text-no-wrap"
                        color="warning"
                        colored-border
                        dense
                    >
                        {{ $t('hoofcare.static.missingNumberOfCowsWarning') }}
                    </v-alert>
                </v-col>
            </v-fade-transition>

            <v-col class="shrink d-flex">
                <v-btn
                    class="mr-3"
                    color="primary"
                    text
                    @click="$router.back()"
                >
                    <v-icon left>
                        mdi-arrow-left
                    </v-icon>
                    {{ $t('general.actions.back') }}
                </v-btn>

                <v-btn
                    v-if="company"
                    :color="! company.setting.cows_count ? 'warning' : 'primary'"
                    elevation="0"
                    @click="showCowSettingsDialog = true"
                >
                    {{ $t('hoofcare.actions.cowSettings') }}
                </v-btn>
            </v-col>
        </v-row>

        <v-row class="align-stretch">
            <v-col cols="12" lg="3" md="4">
                <v-card class="d-flex flex-column justify-space-between fill-height">
                    <v-overlay :value="loading" absolute color="white"></v-overlay>

                    <v-card-title class="subtitle-1">
                        {{ $t('hoofcare.static.mortellaroFreePercentage') }}
                    </v-card-title>

                    <v-card-text :class="$vuetify.breakpoint.lgAndDown ? 'display-3' : 'display-4'"
                                 class="py-3 text-center primary--text font-weight-medium">
                        {{ currentMortellaroPercentage }}%
                    </v-card-text>

                    <v-card-subtitle
                        v-if="company && company.hoofcareStatistics && company.hoofcareStatistics.updatedAt"
                        class="text-end">
                        {{
                            $t('hoofcare.static.lastUpdatedAt', {date: $moment(company.hoofcareStatistics.updatedAt).format('DD-MM-YYYY')})
                        }}
                    </v-card-subtitle>

                    <v-card-subtitle v-else class="text-end">
                        {{ $t('hoofcare.static.lastUpdatedNever') }}
                    </v-card-subtitle>
                </v-card>
            </v-col>

            <v-col cols="12" lg="6" md="8">
                <v-card class="fill-height">
                    <v-overlay :value="loading" absolute color="white"></v-overlay>

                    <v-card-title class="subtitle-1">
                        {{ $t('hoofcare.static.mortellaroFreeTrendline') }}
                    </v-card-title>

                    <trendline-chart
                        v-if="company"
                        id="trendline-chart"
                        :cows-count="company.setting.cows_count"
                        :statistics="company.hoofcareStatistics"
                        class="px-3 pb-3"
                    ></trendline-chart>
                </v-card>
            </v-col>

            <v-col cols="12" lg="3">
                <v-card class="d-flex flex-column fill-height">
                    <v-overlay :value="loading" absolute color="white"></v-overlay>

                    <v-card-title class="subtitle-1">
                        {{ $t('general.static.photos') }}
                    </v-card-title>

                    <v-card-text>
                        {{ $t('hoofcare.static.photoCollageDescription') }}
                    </v-card-text>

                    <v-spacer></v-spacer>

                    <v-card-text v-if="company">
                        <v-btn :to="{ name: 'HoofcarePhotos', params: { companyId: company.id } }" block color="primary"
                               exact outlined>
                            {{ $t('general.actions.photoCollage') }}
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="align-stretch pt-3">
            <v-col cols="12" lg="6">
                <v-card class="fill-height">
                    <v-overlay :value="loading" absolute color="white"></v-overlay>

                    <v-card-title class="subtitle-1">
                        {{ $t('hoofcare.static.mortellaroFreeMonthly') }}
                    </v-card-title>

                    <monthly-chart
                        v-if="company"
                        id="monthly-bar-chart"
                        :cows-count="company.setting.cows_count"
                        :statistics="company.hoofcareStatistics"
                        class="px-3 pb-3"
                        type="bar"
                    ></monthly-chart>
                </v-card>
            </v-col>

            <v-col cols="12" lg="6">
                <v-card class="fill-height">
                    <v-overlay :value="loading" absolute color="white"></v-overlay>

                    <v-card-title class="subtitle-1">
                        {{ $t('hoofcare.static.mortellaroFreeMonthly') }}
                    </v-card-title>

                    <monthly-chart
                        v-if="company"
                        id="monthly-line-chart"
                        :cows-count="company.setting.cows_count"
                        :statistics="company.hoofcareStatistics"
                        class="px-3 pb-3"
                        type="line"
                    ></monthly-chart>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog
            v-model="showCowSettingsDialog"
            max-width="500"
        >
            <v-card>
                <v-card-title>
                    {{ $t('hoofcare.static.cowSettings') }}
                </v-card-title>

                <v-card-text v-if="companySetting" class="pb-0">
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="companySetting.cows_count"
                                :error-messages="validationErrors['cows_count']"
                                :label="$t('hoofcare.static.numberOfCows')"
                                dense
                                outlined
                                @keypress.enter="saveCowSettings"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="px-5 pb-5 pt-0">
                    <v-spacer></v-spacer>

                    <v-btn text @click="showCowSettingsDialog = false">
                        {{ $t('general.actions.cancel') }}
                    </v-btn>

                    <v-btn
                        :loading="savingCowSettings"
                        color="primary"
                        @click="saveCowSettings"
                    >
                        {{ $t('general.actions.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>

</template>

<script>
import TrendlineChart from './details/TrendlineChart';
import MonthlyChart from './details/MonthlyChart';

export default {
    components: {TrendlineChart, MonthlyChart},

    props: {
        companyId: Number,
    },

    data() {
        return {
            showCowSettingsDialog: false,

            company: null,
            companySetting: null,

            validationErrors: {},

            loading: false,
            savingCowSettings: false
        }
    },

    computed: {
        currentMortellaroPercentage() {
            if (!this.company) return 0;

            if (!this.company.hoofcareStatistics) return 0;

            if (!this.company.setting.cows_count) return 0;

            let currentYearStatistics = this.company.hoofcareStatistics.trend.years[moment().year()];

            if (!currentYearStatistics) return 0;

            let currentMonthScore = currentYearStatistics[moment().month()];

            if (!currentMonthScore) return 100;

            return Math.floor(100 - ((currentMonthScore / this.company.setting.cows_count) * 100));
        }
    },

    watch: {
        showCowSettingsDialog() {
            this.validationErrors = {};
        }
    },

    mounted() {
        this.loadCompany();
    },

    methods: {
        loadCompany() {
            this.loading = true;

            axios.get(`/api/companies/${this.companyId}?appends=[hoofcareStatistics]&with=[setting]`)
                .then((response) => {
                    this.company = response.data;

                    this.companySetting = {
                        id: null,
                        company_id: this.company ? this.company.id : null,
                        cows_count: null,
                        ..._.cloneDeep(this.company.setting)
                    };
                })
                .catch(() => {

                })
                .finally(() => {
                    this.loading = false;
                });
        },

        saveCowSettings() {
            this.savingCowSettings = true;

            axios.put(`/api/company-settings/${this.companySetting.id}`, this.companySetting)
                .then((response) => {
                    this.company.setting = response.data;

                    this.$root.showSnackbar(this.$t('hoofcare.static.cowSettingsSaved'), 'success');

                    this.showCowSettingsDialog = false;
                })
                .catch((error) => {
                    if (error.response.status == 422) {
                        this.validationErrors = error.response.data.errors;

                        if (this.validationErrors.company_id) {
                            this.$root.showSnackbar(_.first(this.validationErrors.company_id), 'error');
                        }
                    }
                })
                .finally(() => {
                    this.savingCowSettings = false;
                })
        },
    }
}
</script>
