var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-overlay',{attrs:{"value":_vm.loading,"absolute":"","color":"white"}}),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"shrink"},[_c('v-btn-toggle',{attrs:{"borderless":"","color":"primary","dense":"","multiple":""},model:{value:(_vm.selectedModules),callback:function ($$v) {_vm.selectedModules=$$v},expression:"selectedModules"}},_vm._l((_vm.$root.modules),function(module){return _c('v-btn',{key:module.slug,staticStyle:{"height":"40px"},attrs:{"value":module.slug}},[_vm._v(" "+_vm._s(module.name)+" ")])}),1)],1),_c('v-col',{staticClass:"grow"},[_c('v-text-field',{attrs:{"label":_vm.$t('general.static.search'),"append-icon":"mdi-magnify","color":"primary","dense":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"footer-props":{ itemsPerPageOptions: [10, 25, 50] },"headers":_vm.headers,"items":_vm.companies,"loading":_vm.loading,"options":_vm.dataTableOptions,"server-items-length":_vm.totalCompanies},on:{"update:options":function($event){_vm.dataTableOptions=$event}},scopedSlots:_vm._u([{key:"item.trial_ends_at",fn:function(ref){
var item = ref.item;
return [(item.is_activated)?_c('div',{staticClass:"grey--text"},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")]),_vm._v(" "+_vm._s(_vm.$t('settings.static.activated'))+" ")],1):(! item.hasTrialEnded)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('settings.static.trialUntil', {date: _vm.$moment(item.trial_ends_at).format('DD-MM-YYYY')}))+" ")]):_c('div',{staticClass:"grey--text"},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")]),_vm._v(" "+_vm._s(item.trial_ends_at ? _vm.$t('settings.static.inactiveSince', {date: _vm.$moment(item.trial_ends_at).format('DD-MM-YYYY')}) : _vm.$t('general.static.inactive'))+" ")],1)]}},{key:"item.users_count",fn:function(ref){
var item = ref.item;
return [_c('div',{class:item.users_count > 0 ? '' : 'error--text'},[_vm._v(" "+_vm._s(item.users_count)+" ")])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.address ? item.address.asString : '')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editCompany(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.confirmDeleteCompany(item)}}},[_vm._v(" mdi-delete ")])],1)]}}])}),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.makeCompany}},[_vm._v(" "+_vm._s(_vm.$t('settings.actions.addCompany'))+" ")])],1),_c('company-dialog',{attrs:{"company":_vm.selectedCompany},on:{"created":_vm.load,"updated":function($event){_vm.$root.loadAuthenticatedUser(true);_vm.load()}},model:{value:(_vm.showCompanyDialog),callback:function ($$v) {_vm.showCompanyDialog=$$v},expression:"showCompanyDialog"}}),_c('confirm-delete-dialog',{attrs:{"company":_vm.selectedCompany},on:{"deleted":function($event){_vm.$root.loadAuthenticatedUser(true);_vm.load()}},model:{value:(_vm.showConfirmDeleteDialog),callback:function ($$v) {_vm.showConfirmDeleteDialog=$$v},expression:"showConfirmDeleteDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }