<template>

    <v-card>
        <v-overlay :value="loading || impersonatingLocationId" absolute color="white"></v-overlay>

        <v-card-text>
            <v-text-field
                v-model="search"
                :label="$t('general.static.search')"
                append-icon="mdi-magnify"
                color="primary"
                dense
                hide-details
                outlined
            ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-data-table
            :footer-props="{ itemsPerPageOptions: [10, 25, 50] }"
            :headers="headers"
            :items="locations"
            :loading="loading"
            :options.sync="dataTableOptions"
            :server-items-length="totalLocations"
        >
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at ? $moment(item.created_at).format('DD-MM-YYYY HH:mm') : '' }}
            </template>

            <template v-slot:item.actions="{ item }">
                <v-icon
                    class="mr-2"
                    small
                    @click="editLocation(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    @click="confirmDeleteLocation(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>

        <v-divider></v-divider>

        <v-card-actions class="pa-4">
            <v-spacer></v-spacer>

            <v-btn color="primary" @click="makeLocation">
                {{ $t('settings.actions.addLocation') }}
            </v-btn>
        </v-card-actions>

        <location-dialog
            v-model="showLocationDialog"
            :location="selectedLocation"
            @created="load"
            @updated="load()"
        ></location-dialog>

        <confirm-delete-dialog
            v-model="showConfirmDeleteDialog"
            :location="selectedLocation"
            @deleted="load()"
        ></confirm-delete-dialog>
    </v-card>

</template>

<script>
import LocationDialog from './locations/LocationDialog';
import ConfirmDeleteDialog from './locations/ConfirmDeleteDialog';

export default {
    components: {LocationDialog, ConfirmDeleteDialog},

    data() {
        return {
            locations: [],
            totalLocations: 0,

            loading: false,

            impersonatingLocationId: null,

            dataTableOptions: {
                sortBy: ['name'],
                sortDesc: [false],
                multiSort: false,
                page: 1,
                itemsPerPage: 25
            },

            search: null,
            searchDebounced: null,
            searchColumns: [
                'name'
            ],

            selectedLocation: null,

            showHoofcareDialog: false,
            showLocationDialog: false,
            showConfirmDeleteDialog: false,
        }
    },

    computed: {
        headers() {
            return [
                {text: this.$t('general.static.name'), value: 'name'},
                {text: this.$t('general.static.createdAt'), value: 'created_at'},
                {value: 'actions', align: 'right', sortable: false},
            ];
        },

        queryParameters() {
            let {sortBy, sortDesc, page, itemsPerPage} = this.dataTableOptions;

            sortBy = _.first(sortBy);
            sortDesc = _.first(sortDesc);

            return [
                `paginate=${itemsPerPage}`,
                `page=${page}`,
                `order_by=${sortBy || 'id'}`,
                `order=${sortDesc ? 'desc' : 'asc'}`,
                ...(this.searchDebounced ? [
                    `search_columns=[${this.searchColumns.join(',')}]`,
                    `search_value=${this.searchDebounced}`,
                ] : [])
            ];
        },

        activeCompany() {
            return this.$root.activeCompany;
        }
    },

    watch: {
        queryParameters: {
            handler() {
                this.load();
            },
            deep: true
        },

        search: _.debounce(function () {
            this.searchDebounced = this.search;
        }, 500),

        activeCompany: {
            handler(company) {
                if (company) {
                    this.load();
                }
            },
            immediate: true
        },
    },

    methods: {
        makeLocation() {
            this.selectedLocation = null;
            this.showLocationDialog = true;
        },

        editLocation(location) {
            this.selectedLocation = location;
            this.showLocationDialog = true;
        },

        confirmDeleteLocation(location) {
            this.selectedLocation = location;
            this.showConfirmDeleteDialog = true;
        },

        load() {
            if (this.loading) return;

            this.loading = true;

            let parameters = [
                ...this.queryParameters,
            ];

            if (this.activeCompany) parameters.push(`where=[company_id|=|${this.activeCompany.id}]`);

            axios.get(`/api/locations?${_.join(parameters, '&')}`)
                .then((response) => {
                    this.locations = response.data.items;
                    this.totalLocations = response.data.total;
                })
                .catch(() => {

                })
                .finally(() => {
                    this.loading = false;
                });
        },
    }
}
</script>
