<template>

    <v-container :fluid="$vuetify.breakpoint.mdAndDown">
        <v-row class="justify-space-between align-center">
            <v-col class="display-1 primary--text font-weight-medium d-flex">
                {{ $t('cleaningAndDisinfection.static.cleaningAndDisinfection') }}

                <div class="pl-3 align-self-center">
                    <v-img v-if="module" :src="module.outlinedIcon" contain height="32" width="32"/>
                </div>
            </v-col>

            <v-col class="shrink d-flex">
                <v-btn
                    color="primary"
                    text
                    @click="$router.push({ name: 'Home' })"
                >
                    <v-icon left>
                        mdi-view-module
                    </v-icon>
                    {{ $t('general.actions.home') }}
                </v-btn>
            </v-col>
        </v-row>

        <v-row class="align-stretch">
            <v-col cols="12" md="6">
                <v-card class="fill-height">
                    <v-overlay :value="loading" absolute color="white"></v-overlay>

                    <v-data-table
                        :custom-sort="tableSort"
                        :headers="headers"
                        :items="companiesWithStatus"
                        :loading="loading"
                        :options.sync="dataTableOptions"
                        disable-filtering
                        disable-pagination
                        hide-default-footer
                        must-sort
                    >
                        <template v-slot:item.cleaningPoultryStatistics.currentScore="{ item }">
                            <v-icon :color="checkpointStatusColors[item.status || 0]">
                                mdi-circle
                            </v-icon>

                            <span class="caption">
                                {{
                                    item.cleaningPoultryStatistics.currentScore === null ? '-' : $_.round(item.cleaningPoultryStatistics.currentScore, 2)
                                }}
                            </span>
                        </template>

                        <template v-slot:item.actions="{ item }">
                            <v-btn
                                color="primary"
                                small
                                text
                                @click="$router.push({ name: 'CleaningPoultryLocations', params: { companyId: item.id } })"
                            >
                                <v-icon left>
                                    mdi-arrow-right
                                </v-icon>
                                {{ $t('general.actions.viewDetails') }}
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>

            <v-col cols="12" md="6">
                <v-card class="fill-height">
                    <v-overlay :value="loading" absolute color="white"></v-overlay>

                    <v-card-text>
                        <pie-chart
                            :chart-data="{ labels: chartLabels, datasets: chartDatasets }"
                            :height="300"
                            chart-id="cleaning-poultry-companies-pie-chart"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
import PieChart from '@/js/components/charts/PieChart';

import CheckpointStatusMixin from '@/js/mixins/checkpointStatus';
import TableMixin from '@/js/mixins/table';

export default {
    components: {PieChart},

    mixins: [CheckpointStatusMixin, TableMixin],

    data() {
        return {
            loading: false,

            dataTableOptions: {
                sortBy: ['name'],
                sortDesc: [false],
            },

            companies: [],
        }
    },

    computed: {
        headers() {
            return [
                {text: this.$t('general.static.location'), value: 'name'},
                {text: this.$t('general.static.status'), value: 'cleaningPoultryStatistics.currentScore'},
                {value: 'actions', align: 'right', sortable: false},
            ];
        },

        queryParameters() {
            let parameters = [
                `appends=[cleaningPoultryStatistics]`,
                `where_in=id|${_.map(this.user.companies, 'id')}`
            ];

            return parameters;
        },

        companiesWithStatus() {
            return _.map(this.companies, (company) => {
                return {
                    status: this.getStatusForScore(company.cleaningPoultryStatistics.currentScore),
                    ...company
                }
            });
        },

        chartLabels() {
            return Object.values(this.checkpointStatusLabels);
        },

        chartDatasets() {
            return [{
                backgroundColor: Object.values(this.checkpointStatusColors),
                data: [
                    _.filter(this.companiesWithStatus, {status: null}).length,
                    _.filter(this.companiesWithStatus, {status: 1}).length,
                    _.filter(this.companiesWithStatus, {status: 2}).length,
                    _.filter(this.companiesWithStatus, {status: 3}).length,
                ]
            }];
        },

        user() {
            return this.$root.user;
        },

        module() {
            return _.find(this.$root.modules, {slug: 'cleaning_poultry'});
        }
    },

    mounted() {
        this.load();
    },

    methods: {
        load() {
            if (this.loading) return;

            this.loading = true;

            axios.get(`/api/companies?${_.join(this.queryParameters, '&')}`)
                .then((response) => {
                    this.companies = response.data.items;
                })
                .catch(() => {

                })
                .finally(() => {
                    this.loading = false;
                });
        },
    }
}
</script>
