<template>
    <v-card>
        <v-card-title class="px-6 py-3 title primary--text">
            {{ date ? date.format('DD-MM-YYYY') : '' }}
        </v-card-title>

        <v-card-text class="pa-6 pt-0">
            <div class="pb-3">
                <div v-for="score in scores">
                    <span v-if="score.note" class="text-truncate d-block font-weight-normal">
                        <span class="font-weight-bold">
                            {{ score.checkpoint_number + 1 }}.
                        </span>
                        &nbsp;{{ score.note }}
                    </span>
                </div>
            </div>

            <v-row class="justify-start">
                <v-col v-for="scoreImage in scoreImages" :key="scoreImage.score.id + scoreImage.key" cols="12" md="3"
                       sm="6">
                    <v-card :height="$vuetify.breakpoint.smAndDown ? 200 : 300" color="secondary"
                            outlined @click="selectScore(scoreImage.score, scoreImage.key)">
                        <v-img
                            :src="getScoreImage(scoreImage.score, scoreImage.key)"
                            class="fill-height grey lighten-3"
                        >
                            <div class="d-flex fill-height flex-column">
                                <v-spacer/>

                                <div :class="$vuetify.breakpoint.xsOnly ? 'flex-wrap' : ''" class="d-flex"
                                     style="background-color: rgba(33,33,33,0.5)">
                                    <v-row no-gutters>
                                        <v-col cols="12" md="6" xl="3">
                                            <v-list-item dark dense>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>
                                                        {{ $t('checkpoints.static.checkpoint') }}
                                                    </v-list-item-subtitle>
                                                    <v-list-item-title>
                                                        {{ scoreImage.score.checkpoint_number + 1 }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="12" md="6" xl="3">
                                            <v-list-item dark dense>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>
                                                        {{ $t('waterSolutions.static.swapScore') }}
                                                    </v-list-item-subtitle>
                                                    <v-list-item-title>
                                                        {{ scoreImage.score.normalized_swap_score }}
                                                        ({{ scoreImage.score.swap_score }})
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="12" md="6" xl="3">
                                            <v-list-item dark dense>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>
                                                        {{ statisticData(scoreImage.score, scoreImage.key).label }}
                                                    </v-list-item-subtitle>
                                                    <v-list-item-title>
                                                        {{
                                                            scoreImage.score[statisticData(scoreImage.score, scoreImage.key).score]
                                                        }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="12" md="6" xl="3">
                                            <v-list-item dark dense>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>
                                                        {{ $t('general.static.time') }}
                                                    </v-list-item-subtitle>
                                                    <v-list-item-title>
                                                        {{ $moment(scoreImage.score.saved_at).format('HH:mm') }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>

        <PhotoDialog
            v-model="showPhotoDialog"
            :download-url="selected.imageDownloadUrl"
            :image="selected.image"
            :next-enabled="!! scoreImages[selectedScoreImageIndex + 1]"
            :note="selected.note"
            :previous-enabled="!! scoreImages[selectedScoreImageIndex - 1]"
            :subtitle="`${$t('checkpoints.static.checkpoint')} ${selected.checkpoint}`"
            :thumbnail="selected.imageThumbnail"
            :title="selected.date"
            @next="selectedScoreImageIndex++"
            @previous="selectedScoreImageIndex--"
        />
    </v-card>

</template>

<script>
import PhotoDialog from '@/js/components/PhotoDialog';

export default {
    components: {PhotoDialog},

    props: {
        date: {
            default: null
        },
        scores: {
            type: Array,
            default: []
        }
    },

    computed: {
        scoreImages() {
            let scoreImages = [];

            _.each(this.scores, (score) => {
                _.each(score.downloadUrls, (url, key) => {
                    if (url != null) {
                        scoreImages.push({
                            score: score,
                            key: key,
                        })
                    }
                });
            });

            return scoreImages;
        },

        selected() {
            let scoreImage = this.scoreImages[this.selectedScoreImageIndex];

            if (!scoreImage) return {};

            return {
                note: scoreImage.score.note,
                date: this.date ? this.date.format('DD-MM-YYYY') : '',
                checkpoint: scoreImage.score.checkpoint_number + 1,
                image: this.getScoreImage(scoreImage.score, scoreImage.key, false),
                imageThumbnail: this.getScoreImage(scoreImage.score, scoreImage.key),
                imageDownloadUrl: `${scoreImage.score.downloadUrls[scoreImage.key]}&filename=${this.statisticData(scoreImage.score, scoreImage.key).filename}`,
            }
        },
    },

    data() {
        return {
            selectedScoreImageIndex: null,

            showPhotoDialog: false,
        }
    },

    methods: {
        statisticData(score, key) {
            switch (key) {
                case 'cleaning_result':
                    return {
                        score: 'cleaning_result_score',
                        label: this.$t('waterSolutions.static.cleaningResultScore'),
                        filename: `${this.date ? this.date.format('DD-MM-YYYY') : ''} ${this.$t('waterSolutions.static.cleaningResultScore')} ${score.cleaning_result_score} ${this.$t('checkpoints.static.checkpoint')} ${score.checkpoint_number + 1}`,
                    };
                case 'endoscope':
                    return {
                        score: 'endoscope_score',
                        label: this.$t('waterSolutions.static.endoscopeScore'),
                        filename: `${this.date ? this.date.format('DD-MM-YYYY') : ''} ${this.$t('waterSolutions.static.endoscopeScore')} ${score.endoscope_score} ${this.$t('checkpoints.static.checkpoint')} ${score.checkpoint_number + 1}`,
                    };
            }
        },

        filename(score) {
            return `${this.date ? this.date.format('DD-MM-YYYY') : ''} ${this.$t('waterSolutions.static.swapScore')} ${score.swap_score}, ${this.$t('waterSolutions.static.cleaningResultScore')} ${score.cleaning_result_score} ${this.$t('checkpoints.static.checkpoint')} ${score.checkpoint_number + 1}`;
        },

        getScoreImage(score, key, thumbnail = true) {
            return thumbnail && score.base64Images[key] ? `data:image/png;base64, ${score.base64Images[key]}` : score.downloadUrls[key];
        },

        selectScore(score, key) {
            this.selectedScoreImageIndex = _.findIndex(this.scoreImages, (scoreImage) => {
                return scoreImage.score.id === score.id && scoreImage.key === key;
            });

            this.showPhotoDialog = true;
        }
    },
}
</script>
