<template>

    <v-card>
        <v-overlay :value="loading" absolute color="white"></v-overlay>

        <v-data-table
            :footer-props="{ itemsPerPageOptions: [10, 25, 50] }"
            :headers="headers"
            :items="users"
            :loading="loading"
            :options.sync="dataTableOptions"
            :server-items-length="totalUsers"
        >
            <template v-slot:item.first_name="{ item }">
                {{ item.name }}
            </template>

            <template v-slot:item.actions="{ item }">
                <v-icon
                    class="mr-2"
                    small
                    @click="editUser(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    :disabled="item.id == authenticatedUser.id"
                    small
                    @click="confirmRemoveUser(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>

        <v-divider></v-divider>

        <v-card-actions class="pa-4">
            <v-spacer></v-spacer>

        </v-card-actions>

        <user-dialog
            v-model="showUserDialog"
            :user="selectedUser"
            @created="load"
            @updated="load"
        ></user-dialog>

        <confirm-remove-dialog
            v-model="showConfirmRemoveDialog"
            :user="selectedUser"
            @removed="load"
        ></confirm-remove-dialog>
    </v-card>

</template>

<script>
import UserDialog from './users/UserDialog';
import ConfirmRemoveDialog from './users/ConfirmRemoveDialog';

export default {
    components: {UserDialog, ConfirmRemoveDialog},

    data() {
        return {
            users: [],
            totalUsers: 0,

            loading: false,

            dataTableOptions: {
                sortBy: ['first_name'],
                sortDesc: [false],
                multiSort: false,
                page: 1,
                itemsPerPage: 25
            },

            selectedUser: null,

            showUserDialog: false,
            showConfirmRemoveDialog: false,
        }
    },

    computed: {
        authenticatedUser() {
            return this.$root.user;
        },

        activeCompany() {
            return this.$root.activeCompany;
        },

        headers() {
            return [
                {text: this.$t('settings.static.name'), value: 'first_name'},
                {text: this.$t('settings.static.email'), value: 'email'},
                {value: 'actions', align: 'right', sortable: false},
            ];
        },

        queryParameters() {
            let {sortBy, sortDesc, page, itemsPerPage} = this.dataTableOptions;

            sortBy = _.first(sortBy);
            sortDesc = _.first(sortDesc);

            return [
                `paginate=${itemsPerPage}`,
                `page=${page}`,
                `order_by=${sortBy || 'id'}`,
                `order=${sortDesc ? 'desc' : 'asc'}`,
            ];
        },
    },

    watch: {
        queryParameters: {
            handler() {
                this.load();
            },
            deep: true
        },

        activeCompany: {
            handler(company) {
                if (company) {
                    this.load();
                }
            },
            immediate: true
        },
    },

    methods: {
        makeUser() {
            this.selectedUser = null;
            this.showUserDialog = true;
        },

        editUser(user) {
            this.selectedUser = user;
            this.showUserDialog = true;
        },

        confirmRemoveUser(user) {
            this.selectedUser = user;
            this.showConfirmRemoveDialog = true;
        },

        load() {
            this.loading = true;

            let parameters = [
                `scopes=[whereCompany|${this.activeCompany.id}]`,
                ...this.queryParameters,
            ];

            axios.get(`/api/users?${_.join(parameters, '&')}`)
                .then((response) => {
                    this.users = response.data.items;
                    this.totalUsers = response.data.total;
                })
                .catch(() => {

                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
}
</script>
