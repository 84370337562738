import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/js/i18n'
import '@/sass/overrides.scss'

Vue.use(Vuetify)

const theme = {
    primary: '#008e4f',
    secondary: '#444f57',
    accent: '#25b255',
    error: '#e32e24',
    info: '#358ac4',
    success: '#5ce154',
    warning: '#ff6034',
    tertiary: '#ffb200'
};

export default new Vuetify({
    lang: {
        t: (key, ...params) => i18n.t(key, params),
    },
    theme: {
        themes: {
            dark: theme,
            light: theme,
        },
    },
})
