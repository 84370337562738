<template>

    <v-dialog
        :value="value"
        max-width="400"
        @input="$emit('input', $event)"
    >
        <v-card>
            <v-card-title>
                {{ $t('settings.static.confirmLogout') }}
            </v-card-title>

            <v-card-actions class="px-5 pb-5 pt-0">
                <v-spacer></v-spacer>

                <v-btn text @click="$emit('input', false)">
                    {{ $t('general.actions.cancel') }}
                </v-btn>

                <v-btn
                    :loading="$root.loggingOut"
                    color="primary"
                    @click="$root.logout"
                >
                    {{ $t('auth.actions.logout') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>
export default {
    props: {
        value: Boolean,
    },
}
</script>
