<template>

    <v-autocomplete
        v-if="text"
        :error-messages="validationErrors"
        :items="languages"
        :label="$t('general.static.language')"
        :value="value"
        dense
        item-value="code"
        outlined
        @input="$emit('input', $event)"
    >
        <template v-slot:selection="{ item }">
            <v-avatar class="mr-2" left size="18">
                <v-img :src="item.svg"></v-img>
            </v-avatar>
            {{ item.name }}
        </template>

        <template v-slot:item="{ item }">
            <v-list-item-avatar size="24">
                <v-img :src="item.svg"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>
                    {{ item.name }}
                </v-list-item-title>
            </v-list-item-content>
        </template>
    </v-autocomplete>

    <v-menu v-else :disabled="loading" bottom left offset-y>
        <template v-slot:activator="{ on }">
            <v-btn
                :class="btnClasses"
                :icon="$vuetify.breakpoint.smAndDown"
                :loading="loading"
                :outlined="! $vuetify.breakpoint.smAndDown"
                :small="$vuetify.breakpoint.xsOnly"
                :style="{ width: $vuetify.breakpoint.smAndDown ? null : `36px` }"
                color="primary"
                style="min-width: 0;"
                v-on="on"
            >
                <v-avatar v-if="selected" size="18">
                    <v-img :src="selected.svg"></v-img>
                </v-avatar>
                <v-icon v-else>
                    mdi-web
                </v-icon>
            </v-btn>
        </template>

        <v-list>
            <v-list-item v-for="language in languages" :key="language.code" @click="$emit('input', language.code)">
                <v-list-item-avatar size="24">
                    <v-img :src="language.svg"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ language.name }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>

</template>

<script>
export default {
    props: {
        value: String,
        btnClasses: String,
        loading: Boolean,
        text: Boolean,
        validationErrors: Array,
    },

    computed: {
        languages() {
            return [
                {code: 'en', name: 'English', svg: require('svg-country-flags/svg/gb.svg'),},
                {code: 'de', name: 'Deutsch', svg: require('svg-country-flags/svg/de.svg'),},
                {code: 'fr', name: 'Français', svg: require('svg-country-flags/svg/fr.svg'),},
                {code: 'es', name: 'Español', svg: require('svg-country-flags/svg/es.svg'),},
                {code: 'nl', name: 'Nederlands', svg: require('svg-country-flags/svg/nl.svg'),},
                {code: 'zh_Hans', name: '中国人', svg: require('svg-country-flags/svg/cn.svg'),},
                {code: 'ja', name: '日本', svg: require('svg-country-flags/svg/jp.svg'),},
                {code: 'zh_Hant', name: '臺灣的', svg: require('svg-country-flags/svg/tw.svg'),},
                {code: 'vn', name: 'tiếng Việt', svg: require('svg-country-flags/svg/vn.svg'),},
            ];
        },

        selected() {
            return _.find(this.languages, {code: this.value});
        }
    },

}
</script>
