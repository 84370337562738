<template>

</template>

<script>
export default {
    data() {
        return {};
    },

    created() {
        this.$router.replace({name: 'Home'});
    },

    methods: {}
}
</script>
