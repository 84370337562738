const userAgentMixin = {
    computed: {
        isAndroid() {
            return this.$uaParser.os.name === 'Android'
                || this.$uaParser.os.name === 'Android-x86';
        },

        isIOS() {
            return this.$uaParser.os.name === 'iOS';
        },

        isMobile() {
            return this.isAndroid || this.isIOS;
        }
    },
}

export default userAgentMixin;
