import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from 'vuetify/lib/locale/en'
import de from 'vuetify/lib/locale/de'
import fr from 'vuetify/lib/locale/fr'
import es from 'vuetify/lib/locale/es'
import nl from 'vuetify/lib/locale/nl'
import ja from 'vuetify/lib/locale/ja'
import zhHans from 'vuetify/lib/locale/zh-Hans'
import zhHant from 'vuetify/lib/locale/zh-Hant'

import * as enStrings from '@/js/../lang/en.json';
import * as deStrings from '@/js/../lang/de.json';
import * as frStrings from '@/js/../lang/fr.json';
import * as esStrings from '@/js/../lang/es.json';
import * as nlStrings from '@/js/../lang/nl.json';
import * as jaStrings from '@/js/../lang/ja.json';
import * as zhHansStrings from '@/js/../lang/zh_Hans.json';
import * as zhHantStrings from '@/js/../lang/zh_Hant.json';
import * as vnStrings from '@/js/../lang/vn.json';

Vue.use(VueI18n)

const messages = {
    en: {
        $vuetify: en,
        ...enStrings.default
    },
    de: {
        $vuetify: de,
        ...enStrings.default,
        ...deStrings.default,
    },
    fr: {
        $vuetify: fr,
        ...enStrings.default,
        ...frStrings.default,
    },
    es: {
        $vuetify: es,
        ...enStrings.default,
        ...esStrings.default,
    },
    nl: {
        $vuetify: nl,
        ...enStrings.default,
        ...nlStrings.default,
    },
    ja: {
        $vuetify: ja,
        ...enStrings.default,
        ...jaStrings.default,
    },
    zh_Hans: {
        $vuetify: zhHans,
        ...enStrings.default,
        ...zhHansStrings.default,
    },
    zh_Hant: {
        $vuetify: zhHant,
        ...enStrings.default,
        ...zhHantStrings.default,
    },
    vn: {
        $vuetify: ja,
        ...enStrings.default,
        ...vnStrings.default,
    },
}

export default new VueI18n({
    locale: _.first(_.split(navigator.language, '-')),
    fallbackLocale: 'en',
    messages,
})
