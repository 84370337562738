<template>

    <v-autocomplete
        :error-messages="validationErrors"
        :items="modules"
        :label="label || $t('general.static.modules')"
        :loading="loading"
        :multiple="multiple"
        :value="value"
        dense
        hide-selected
        item-text="name"
        item-value="slug"
        outlined
        @input="$emit('input', $event)"
    >
        <template v-slot:selection="data">
            <v-chip
                :input-value="data.selected"
                close
                color="primary"
                small
                v-bind="data.attrs"
                @click="data.select"
                @click:close="deselect(data.item)"
            >
                {{ data.item.name }}
            </v-chip>
        </template>

        <template v-slot:item="{ item }">
            <v-list-item-content>
                <v-list-item-title>
                    {{ item.name }}
                </v-list-item-title>
            </v-list-item-content>
        </template>
    </v-autocomplete>

</template>

<script>
export default {
    props: {
        value: Array,
        validationErrors: Array,
        multiple: Boolean,
        label: String,
    },

    data() {
        return {
            loading: false,
        }
    },

    computed: {
        modules() {
            return this.$root.modules;
        }
    },

    methods: {
        deselect(module) {
            this.$emit('input', _.filter(this.value, (slug) => slug != module.slug));
        },
    },
}
</script>
