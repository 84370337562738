<template>

    <div>
        <bar-chart
            v-if="type == 'bar'"
            :chart-data="{ labels: labels, datasets: datasets }"
            :chart-id="id"
            :height="height"
            :max="100"
            :min="0"
        />

        <line-chart
            v-if="type == 'line'"
            :chart-data="{ labels: labels, datasets: datasets }"
            :chart-id="id"
            :height="height"
            :max="100"
            :min="0"
        />
    </div>

</template>

<script>
import BarChart from '@/js/components/charts/BarChart';
import LineChart from '@/js/components/charts/LineChart';

export default {
    components: {BarChart, LineChart},

    props: {
        id: String,
        type: String,
        statistics: Object,
        cowsCount: {
            default: 0
        },
        height: Number
    },

    data() {
        return {}
    },

    computed: {
        datasets() {
            if (!this.statistics) return [];

            if (!this.statistics.monthly.years[moment().year()]) return [];

            let i = 0;

            return _.map(['m0', 'm1', 'm2', 'm3'], (m) => {
                let values = _.map(this.statistics.monthly.years[moment().year()], (score) => {
                    if (score === null) return null;

                    if (m == 'm0') return _.floor(100 - (((score.m1 + score.m2 + score.m3) / (this.cowsCount * 4)) * 100), 2);
                    else return _.floor((score[m] / (this.cowsCount * 4)) * 100, 2);
                });

                switch (this.type) {
                    case 'bar':
                        return {
                            label: m,
                            data: values,
                            backgroundColor: this.chartColors[i++],
                        };
                    case 'line':
                        return {
                            label: m,
                            data: values,
                            backgroundColor: 'transparent',
                            borderColor: this.chartColors[i++],
                            pointBackgroundColor: '#ffffff',
                            borderWidth: 2,
                            lineTension: 0
                        };
                }


            });
        },

        chartColors() {
            return [
                this.$vuetify.theme.currentTheme.primary,
                this.$vuetify.theme.currentTheme.accent,
                this.$vuetify.theme.currentTheme.warning,
                this.$vuetify.theme.currentTheme.error
            ];
        },

        labels() {
            const labels = [];

            for (let i = 0; i < 12; i++) {
                labels.push(i);
            }

            return labels;
        },
    },
}
</script>
