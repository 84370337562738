const tableMixin = {
    methods: {
        tableSort(items, sortBy, sortDesc) {
            sortBy = _.first(sortBy);
            sortDesc = _.first(sortDesc);

            items = _.sortBy(items, (item) => {
                const value = _.get(item, sortBy);

                switch (true) {
                    case _.isNull(value):
                        return -1;
                    case _.isString(value):
                        return _.lowerCase(value);
                    default:
                        return value;
                }
            });

            return sortDesc ? items.reverse() : items;
        },
    },
}

export default tableMixin;
