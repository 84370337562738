<template>

    <v-snackbar
        v-model="show"
        :color="color"
        top
    >
        <v-icon v-if="icon">
            {{ icon }}
        </v-icon>

        {{ text }}
    </v-snackbar>

</template>

<script>
export default {
    props: {
        value: String,

        type: {
            type: String,
            default: 'primary'
        },
    },

    data() {
        return {
            show: false,

            text: ''
        }
    },

    computed: {
        color() {
            switch (this.type) {
                case 'success':
                    return 'success';
                case 'error':
                    return 'error';
                default:
                    return 'primary';
            }
        },

        icon() {
            switch (this.type) {
                case 'success':
                    return 'mdi-check';
                case 'error':
                    return 'mdi-exclamation';
                default:
                    return null;
            }
        }
    },

    watch: {
        value() {
            if (!this.value) return;

            this.show = false;

            this.$nextTick(() => {
                this.text = this.value;

                this.show = true;

                this.$emit('input', null);
            });
        },
    }
}
</script>
