<template>

    <v-card class="fill-height">
        <v-overlay :value="loading" absolute color="white"></v-overlay>

        <v-fade-transition>
            <div v-if="location">
                <v-card-title class="title primary--text">
                    {{ location.name }}

                    <v-spacer/>

                    <small v-if="location.cleaningPoultryStatistics && location.cleaningPoultryStatistics.updatedAt"
                           class="caption grey--text font-weight-regular">
                        {{
                            $t('general.static.lastCheckedAt', {date: $moment(location.cleaningPoultryStatistics.updatedAt).format('DD-MM-YYYY HH:mm')})
                        }}
                    </small>
                </v-card-title>

                <v-data-table
                    :headers="headers"
                    :items="lastScoresWithAverage"
                    disable-filtering
                    disable-pagination
                    disable-sort
                    hide-default-footer
                >
                    <template v-slot:item.normalized_swap_score="{ item }">
                        {{ item.normalized_swap_score == null ? '-' : item.normalized_swap_score }}
                    </template>

                    <template v-slot:item.drinking_lines_score="{ item }">
                        {{ item.drinking_lines_score == null ? '-' : item.drinking_lines_score }}
                    </template>

                    <template v-slot:item.floors_score="{ item }">
                        {{ item.floors_score == null ? '-' : item.floors_score }}
                    </template>
                </v-data-table>
            </div>
        </v-fade-transition>
    </v-card>

</template>

<script>
export default {
    props: {
        location: Object,

        loading: Boolean
    },

    computed: {
        lastScoresWithAverage() {
            if (!this.location) return [];

            let rows = [0, 1, 2, 3, 'average'];

            return _.map(rows, (checkpointNumber) => {
                if (checkpointNumber === 'average') {
                    let hasScores = !_.isEmpty(this.location.cleaningPoultryStatistics.lastScores);

                    return {
                        number: this.$t('checkpoints.static.average'),
                        normalized_swap_score: hasScores
                            ? (!_.isEmpty(this.filteredScores('normalized_swap_score')) ? _.round(_.sumBy(this.filteredScores('normalized_swap_score'), 'normalized_swap_score') / this.filteredScores('normalized_swap_score').length, 2) : null)
                            : null,
                        drinking_lines_score: hasScores
                            ? (!_.isEmpty(this.filteredScores('drinking_lines_score')) ? _.round(_.sumBy(this.location.cleaningPoultryStatistics.lastScores, 'drinking_lines_score') / this.filteredScores('drinking_lines_score').length, 2) : null)
                            : null,
                        floors_score: hasScores
                            ? (!_.isEmpty(this.filteredScores('floors_score')) ? _.round(_.sumBy(this.location.cleaningPoultryStatistics.lastScores, 'floors_score') / this.filteredScores('floors_score').length, 2) : null)
                            : null,
                    };
                }

                let row = _.find(this.location.cleaningPoultryStatistics.lastScores, {checkpoint_number: checkpointNumber}) || {
                    normalized_swap_score: null,
                    checkpoint_number: checkpointNumber,
                    drinking_lines_score: null,
                    floors_score: null,
                };

                row.number = row.checkpoint_number + 1;

                return row;
            });
        },

        headers() {
            return [
                {text: this.$t('checkpoints.static.checkpoints'), value: 'number'},
                {text: this.$t('cleaningAndDisinfection.static.swapScore'), value: 'normalized_swap_score'},
                {text: this.$t('cleaningAndDisinfection.static.drinkingLines'), value: 'drinking_lines_score'},
                {text: this.$t('cleaningAndDisinfection.static.floor'), value: 'floors_score'},
            ];
        },
    },

    methods: {
        filteredScores(attribute) {
            return _.filter(this.location.cleaningPoultryStatistics.lastScores, (score) => score[attribute] !== null);
        }
    }
}
</script>
