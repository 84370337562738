<template>

    <div>
        <v-dialog
            :value="value"
            max-width="500"
            @input="$emit('input', $event)"
        >
            <v-card>
                <v-card-title>
                    {{ exists ? $t('settings.static.editLocation') : $t('settings.static.createLocation') }}

                    <v-spacer></v-spacer>

                    <small v-if="data && data.updated_at" class="caption grey--text font-weight-regular">
                        {{
                            $t('general.static.lastUpdatedAt', {date: $moment(data.updated_at).format('DD-MM-YYYY HH:mm')})
                        }}
                    </small>
                </v-card-title>

                <v-card-text v-if="data" class="pb-0">
                    <v-text-field
                        v-model="data.name"
                        :error-messages="validationErrors['name']"
                        :label="$t('settings.static.name')"
                        dense
                        outlined
                    ></v-text-field>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions v-if="data" class="px-6 py-5">
                    <v-spacer></v-spacer>

                    <v-btn text @click="$emit('input', false)">
                        {{ $t('general.actions.cancel') }}
                    </v-btn>

                    <v-btn
                        :loading="saving"
                        color="primary"
                        @click="save()"
                    >
                        {{ exists ? $t('general.actions.save') : $t('general.actions.create') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
export default {
    props: {
        value: Boolean,
        location: Object,
    },

    data() {
        return {
            data: null,

            showConfirmMergeDialog: false,
            mergeConfirmed: false,

            saving: false,

            validationErrors: {},
        }
    },

    computed: {
        activeCompany() {
            return this.$root.activeCompany;
        },

        exists() {
            return !this.data || !!this.data.id;
        },
    },

    watch: {
        value() {
            this.data = {
                company_id: this.activeCompany ? this.activeCompany.id : null,
                name: null,
                ..._.cloneDeep(this.location)
            };

            this.validationErrors = {};
        },
    },

    methods: {
        async save() {
            this.saving = true;

            let request = this.exists
                ? this.update()
                : this.create();

            request.then((response) => {
                let message = this.exists
                    ? this.$t('settings.static.locationUpdated')
                    : this.$t('settings.static.locationCreated');

                this.$root.showSnackbar(message, 'success');

                this.$emit(this.exists ? 'updated' : 'created');

                this.$emit('input', false);
            }).catch((error) => {
                if (error.response.status == 422) {
                    this.validationErrors = error.response.data.errors;
                }
            }).finally(() => {
                this.saving = false;
            });
        },

        create() {
            return axios.post(`/api/locations`, this.data);
        },

        update() {
            return axios.put(`/api/locations/${this.data.id}`, this.data);
        },
    }
}
</script>
