<template>

    <v-card class="text-center" elevation="3" outlined width="600">
        <v-card-title class="justify-center primary--text">
            {{ $t('auth.static.setPassword') }}
        </v-card-title>
        <v-card-subtitle>
            {{ $t('auth.static.setPasswordDescription') }}
        </v-card-subtitle>

        <v-card-text>
            <v-expand-transition>
                <v-alert
                    v-if="validationErrors['token']"
                    color="error"
                    outlined
                >
                    {{ validationErrors['token'][0] }}
                </v-alert>
            </v-expand-transition>

            <v-text-field
                id="email"
                v-model="form.email"
                :error-messages="validationErrors['email']"
                :label="$t('auth.static.email')"
                dense
                outlined
                readonly
            ></v-text-field>

            <v-text-field
                id="password"
                v-model="form.password"
                :error-messages="validationErrors['password']"
                :label="$t('auth.static.password')"
                :type="passwordInputType"
                autofocus
                dense
                outlined
            >
                <template v-slot:append>
                    <v-icon tabindex="-1"
                            @click="passwordInputType = passwordInputType == 'password' ? 'text' : 'password'">
                        {{ passwordInputType == 'password' ? 'mdi-eye' : 'mdi-eye-off' }}
                    </v-icon>
                </template>
            </v-text-field>

            <v-text-field
                id="password-confirmation"
                v-model="form.password_confirmation"
                :error-messages="validationErrors['password_confirmation']"
                :label="$t('auth.static.confirmPassword')"
                :type="passwordConfirmationInputType"
                dense
                outlined
            >
                <template v-slot:append>
                    <v-icon
                        tabindex="-1"
                        @click="passwordConfirmationInputType = passwordConfirmationInputType == 'password' ? 'text' : 'password'">
                        {{ passwordConfirmationInputType == 'password' ? 'mdi-eye' : 'mdi-eye-off' }}
                    </v-icon>
                </template>
            </v-text-field>

            <v-btn
                :loading="resettingPassword"
                block
                class="my-3"
                color="primary"
                @click="resetPassword"
            >
                {{ $t('auth.actions.setPassword') }}
            </v-btn>
        </v-card-text>
    </v-card>

</template>

<script>
export default {
    data() {
        return {
            form: {
                password: null,
                password_confirmation: null,
                token: window.passwordReset ? window.passwordReset.token : null,
                email: window.passwordReset ? window.passwordReset.email : null,
            },

            validationErrors: {},

            passwordInputType: 'password',
            passwordConfirmationInputType: 'password',

            resettingPassword: false,
        }
    },

    methods: {
        resetPassword() {
            this.resettingPassword = true;

            axios.post(`/api/auth/password-reset?locale=${this.$i18n.locale}`, this.form)
                .then((response) => {
                    this.$root.showSnackbar(this.$t('auth.static.setPasswordSuccessfull'), 'success');

                    this.$router.replace({name: 'Login'});
                })
                .catch((error) => {
                    if (error.response.status == 422) {
                        this.validationErrors = error.response.data.errors;
                    }
                })
                .finally(() => {
                    this.resettingPassword = false;
                });
        }
    }
}
</script>
