<template>

    <v-container :fluid="$vuetify.breakpoint.mdAndDown">
        <v-row class="justify-space-between align-center">
            <v-col class="display-1 primary--text font-weight-medium d-flex flex-wrap">
                {{ $t('hoofcare.static.hoofcare') }}

                <v-fade-transition mode="out-in">
                    <v-progress-circular
                        v-if="loadingCompany"
                        class="ml-3"
                        indeterminate
                        size="32"
                    ></v-progress-circular>
                    <small v-else-if="company"
                           class="secondary--text text--lighten-2 font-weight-regular pl-3 pt-1 mb-n2">
                        - {{ company.name }}
                    </small>
                </v-fade-transition>
            </v-col>

            <v-col class="shrink d-flex">
                <v-btn
                    color="primary"
                    text
                    @click="$router.back()"
                >
                    <v-icon left>
                        mdi-arrow-left
                    </v-icon>
                    {{ $t('general.actions.back') }}
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col
                v-for="(animal, index) in animals"
                :key="animal.code"
                cols="12"
            >
                <div v-if="index == animals.length - 1" v-intersect="onLastElementIntersect"></div>

                <photos-card :animal="animal"/>
            </v-col>

            <v-col cols="12">
                <v-fade-transition mode="out-in">
                    <v-card v-if="loading" key="0" loading>
                        <v-card-subtitle class="text-center">
                            {{
                                pagesLoaded <= 0 ? $t('general.static.loading') : $t('general.static.loadingMoreResults')
                            }}
                        </v-card-subtitle>
                    </v-card>

                    <v-card v-else-if="reachedEndOfPages" key="1" color="primary" dark>
                        <v-card-subtitle class="text-center">
                            {{ $t('general.static.noMoreResults') }}
                        </v-card-subtitle>
                    </v-card>
                </v-fade-transition>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
import PhotosCard from './photos/PhotosCard';

export default {
    components: {PhotosCard},

    props: {
        companyId: Number,
    },

    data() {
        return {
            loading: false,
            loadingCompany: false,

            company: null,

            scores: [],
            animals: [],
            pagesLoaded: 0,
            reachedEndOfPages: false,
        }
    },

    mounted() {
        this.loadCompany();
    },

    methods: {
        loadCompany() {
            this.loadingCompany = true;

            axios.get(`/api/companies/${this.companyId}`)
                .then((response) => {
                    this.company = response.data;

                    this.loadNextPage();
                })
                .catch(() => {

                })
                .finally(() => {
                    this.loadingCompany = false;
                });
        },

        onLastElementIntersect(entries, observer, isIntersecting) {
            if (isIntersecting) this.loadNextPage();
        },

        loadNextPage() {
            if (!this.company) return;

            if (this.loading) return;

            if (this.reachedEndOfPages) return;

            this.loading = true;

            let parameters = [
                `paginate=40`,
                `page=${this.pagesLoaded + 1}`,
                `appends=[mortellaroScoreSummary]`,
                `where=[company_id|=|${this.company.id}]`,
                `order_by=code`,
                `order=asc`,
            ];

            axios.get(`/api/animals?${_.join(parameters, '&')}`)
                .then((response) => {
                    this.pagesLoaded++;

                    if (_.isEmpty(response.data.items)) {
                        this.reachedEndOfPages = true;

                        return;
                    }

                    this.animals.push(...response.data.items);
                })
                .catch(() => {

                })
                .finally(() => {
                    this.loading = false;
                })
        },

        old_loadNextPage() {
            if (!this.company) return;

            if (this.loading) return;

            if (this.reachedEndOfPages) return;

            this.loading = true;

            let parameters = [
                `paginate=40`,
                `page=${this.pagesLoaded + 1}`,
                `appends=[downloadUrls,base64Images]`,
                `with=[animal]`,
                `where=[animal.company_id|=|${this.company.id}]`,
                `order_by=saved_at`,
                `order=desc`,
            ];

            axios.get(`/api/mortellaro-scores?${_.join(parameters, '&')}`)
                .then((response) => {
                    this.pagesLoaded++;

                    if (_.isEmpty(response.data.items)) {
                        this.reachedEndOfPages = true;

                        return;
                    }

                    this.scores.push(...response.data.items);
                })
                .catch(() => {

                })
                .finally(() => {
                    this.loading = false;
                });
        },
    }
}
</script>
