<template>

    <v-dialog
        :value="value"
        max-width="400"
        @input="$emit('input', $event)"
    >
        <v-card>
            <v-card-title>
                {{ $t('settings.static.confirmDeleteAnimal') }}
            </v-card-title>

            <v-card-text>
                {{ $t('settings.static.confirmDeleteAnimalDescription') }}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="px-6 py-5">
                <v-spacer></v-spacer>

                <v-btn text @click="$emit('input', false)">
                    {{ $t('general.actions.cancel') }}
                </v-btn>

                <v-btn
                    :loading="removing"
                    color="primary"
                    @click="destroy"
                >
                    {{ $t('general.actions.delete') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>
export default {
    props: {
        value: Boolean,
        animal: Object,
    },

    data() {
        return {
            removing: false
        }
    },

    methods: {
        destroy() {
            this.removing = true;

            axios.delete(`/api/animals/${this.animal.id}`)
                .then(() => {
                    this.$root.showSnackbar(this.$t('settings.static.animalDeleted'), 'success');

                    this.$emit('deleted');

                    this.$emit('input', false);
                })
                .catch(() => {
                    this.$root.showSnackbar(this.$t('settings.static.animalDeleteFailed'), 'error');
                })
                .finally(() => {
                    this.removing = false;
                });
        }
    }
}
</script>
