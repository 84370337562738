const checkpointStatusMixin = {
    computed: {
        checkpointStatusColors() {
            return {
                0: this.$vuetify.theme.currentTheme.info,
                1: this.$vuetify.theme.currentTheme.success,
                2: this.$vuetify.theme.currentTheme.tertiary,
                3: this.$vuetify.theme.currentTheme.warning,
            }
        },

        checkpointStatusLabels() {
            return {
                0: this.$t('checkpoints.static.noScore'),
                1: this.$t('checkpoints.static.clean'),
                2: this.$t('checkpoints.static.needsAttention'),
                3: this.$t('checkpoints.static.unclean'),
            };
        },

        checkpointStatusLongLabels() {
            return {
                0: this.$t('checkpoints.static.noScoreDescription'),
                1: this.$t('checkpoints.static.clean'),
                2: this.$t('checkpoints.static.needsAttention'),
                3: this.$t('checkpoints.static.unclean'),
            };
        },

        checkpointStatusScores() {
            return {
                0: -0.01,
                1: 0.5,
                2: 2,
                3: 3,
            };
        }
    },

    methods: {
        getStatusForScore(score) {
            if (score === null) return null;

            score = _.round(score, 2);

            return parseInt(_.findKey(this.checkpointStatusScores, (s) => {
                return score <= s;
            }));
        },
    }
}

export default checkpointStatusMixin;
