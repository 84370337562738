import LocalForage from 'localforage';

class Storage {
    ACTIVE_COMPANY_ID = 'activeCompanyId';

    async set(key, value) {
        return await LocalForage.setItem(key, value);
    }

    async get(key) {
        return await LocalForage.getItem(key);
    }
}

export default Storage;
