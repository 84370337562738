<template>

    <v-container v-if="user" :class="$vuetify.breakpoint.mdAndDown ? '' : 'py-6'"
                 :fluid="$vuetify.breakpoint.mdAndDown">
        <v-row :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : ''" class="flex-nowrap">
            <v-col cols="auto">
                <v-card :width="$vuetify.breakpoint.smAndDown ? 'auto' : '300px'" class="overflow-hidden fill-height"
                        color="secondary">
                    <v-card-subtitle class="pb-0 accent grey--text text--lighten-4">
                        {{ $t('settings.static.welcome') }}
                    </v-card-subtitle>
                    <v-card-title class="pt-0 accent white--text">
                        {{ user.name }}
                    </v-card-title>

                    <v-divider dark/>

                    <v-card-text class="primary pa-0">
                        <v-list-item
                            :color="activeCompany && ! activeCompany.isActive ? 'warning' : 'white'"
                            :dense="$vuetify.breakpoint.xsOnly"
                            :disabled="loadingAuthenticatedUser"
                            dark
                            @click="showCompanyDialog = true"
                        >
                            <v-list-item-icon class="mr-3">
                                <v-progress-circular v-if="loadingAuthenticatedUser" indeterminate size="24"/>
                                <v-icon v-else>
                                    mdi-home-variant
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content v-if="activeCompany">
                                <v-list-item-title>
                                    {{ activeCompany.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ activeCompany.address.asString || $t('company.static.noAddress') }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon>
                                    mdi-chevron-down
                                </v-icon>
                            </v-list-item-action>
                            <v-list-item-action-text v-if="activeCompany && ! activeCompany.isActive"
                                                     class="warning--text">
                                {{ $t('general.static.inactive') }}
                            </v-list-item-action-text>
                        </v-list-item>
                    </v-card-text>

                    <v-divider/>

                    <v-sheet>
                        <v-list class="pb-0" color="secondary">
                            <v-list-item
                                v-for="menuItem in menuItems"
                                :key="menuItem.component"
                                :style="activeComponent == menuItem.component ? menuActiveStyle : menuInactiveStyle"
                                class="transition-ease-in-out"
                                @click="activeComponent = menuItem.component"
                            >
                                <v-list-item-title
                                    :class="activeComponent == menuItem.component ? 'white--text' : 'grey--text text--lighten-2'">
                                    {{ menuItem.name }}
                                </v-list-item-title>
                            </v-list-item>

                            <v-divider></v-divider>

                            <template v-if="user.isAdmin">
                                <v-subheader class="info--text">
                                    {{ $t('settings.static.adminMenu') }}
                                </v-subheader>

                                <v-list-item
                                    v-for="menuItem in adminMenuItems"
                                    :key="menuItem.component"
                                    :style="activeComponent == menuItem.component ? menuActiveStyle : menuInactiveStyle"
                                    class="transition-ease-in-out"
                                    @click="activeComponent = menuItem.component"
                                >
                                    <v-list-item-title
                                        :class="activeComponent == menuItem.component ? 'white--text' : 'grey--text text--lighten-2'">
                                        {{ menuItem.name }}
                                    </v-list-item-title>
                                </v-list-item>

                                <v-divider></v-divider>
                            </template>

                            <v-list-item @click="showConfirmLogoutDialog = true">
                                <v-list-item-title class="grey--text text--lighten-2">
                                    {{ $t('auth.actions.logout') }}
                                </v-list-item-title>

                                <v-list-item-icon>
                                    <v-icon color="white">
                                        mdi-logout
                                    </v-icon>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>
                    </v-sheet>
                </v-card>
            </v-col>

            <v-col class="flex-fill">
                <component :is="activeComponent" class="fill-height"></component>
            </v-col>
        </v-row>

        <v-dialog
            v-model="showCompanyDialog"
            eager
            width="500"
        >
            <v-card>
                <v-card-title class="justify-center primary--text">
                    {{ $t('company.static.setLocation') }}
                </v-card-title>
                <v-card-subtitle class="text-center">
                    {{ $t('company.static.setLocationDescription') }}
                </v-card-subtitle>

                <v-card-text>
                    <v-list class="overflow-y-auto" outlined style="max-height:400px">
                        <v-list-item v-if="activeCompanies.length <= 0">
                            <v-list-item-title>
                                {{ $t('company.static.noActiveCompanies') }}
                            </v-list-item-title>
                        </v-list-item>
                        <template v-for="parentCompany in parentCompanies" v-else>
                            <company-list-item
                                :key="parentCompany.id"
                                :active="companyIsActive(parentCompany)"
                                :company="parentCompany"
                                @click="selectCompany(parentCompany)"
                            ></company-list-item>

                            <company-list-item
                                v-for="company in parentCompany.children"
                                :key="company.id"
                                :active="companyIsActive(company)"
                                :company="company"
                                @click="selectCompany(company)"
                            ></company-list-item>
                        </template>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-dialog>

        <confirm-logout-dialog
            v-model="showConfirmLogoutDialog"
        ></confirm-logout-dialog>

    </v-container>

</template>

<script>
import CompanyDetails from './settings/CompanyDetails';
import Users from './settings/Users';
import Animals from './settings/Animals';
import Locations from './settings/Locations';
import Manuals from './settings/Manuals';
import MyAccount from './settings/MyAccount';
import ConfirmLogoutDialog from './settings/dialogs/ConfirmLogoutDialog';
import AdminUsers from './settings/admin/Users';
import AdminCompanies from './settings/admin/Companies';

import CompanyListItem from '@/js/layouts/dashboard/CompanyListItem';

export default {
    components: {
        CompanyDetails,
        Users,
        Animals,
        Locations,
        Manuals,
        MyAccount,
        ConfirmLogoutDialog,
        AdminUsers,
        AdminCompanies,
        CompanyListItem,
    },

    data() {
        return {
            showCompanyDialog: false,

            activeComponent: 'company-details',

            showConfirmLogoutDialog: false
        }
    },

    computed: {
        menuItems() {
            return _.filter([
                {
                    component: 'company-details',
                    name: this.$t('settings.actions.companyDetails')
                },
                {
                    component: 'users',
                    name: this.$t('settings.actions.users')
                },
                _.find(this.$root.user.availableModules, {slug: 'hoofcare'}) ? {
                    component: 'animals',
                    name: this.$t('settings.actions.animals')
                } : null,
                _.find(this.$root.user.availableModules, (module) => _.indexOf(['cleaning_pigs', 'cleaning_poultry', 'water_solutions'], module.slug) >= 0) ? {
                    component: 'locations',
                    name: this.$t('settings.actions.locations')
                } : null,
                {
                    component: 'my-account',
                    name: this.$t('settings.actions.myAccount')
                },
            ]);
        },

        adminMenuItems() {
            if (!this.user.isAdmin) return [];

            return [
                {
                    component: 'admin-users',
                    name: this.$t('settings.actions.allUsers')
                },
                {
                    component: 'admin-companies',
                    name: this.$t('settings.actions.companies')
                },
            ];
        },

        activeCompanies() {
            return this.$root.activeCompanies;
        },

        parentCompanies() {
            return this.$root.parentCompanies;
        },

        user() {
            return this.$root.user;
        },

        menuActiveStyle() {
            return {
                'border-left': `5px solid ${this.$vuetify.theme.currentTheme.accent}`,
                'transition-duration': `0.1s !important`
            }
        },

        menuInactiveStyle() {
            return {
                'border-left': `0px solid ${this.$vuetify.theme.currentTheme.accent}`,
                'transition-duration': `0.1s !important`
            }
        },

        activeCompany: {
            get() {
                return this.$root.activeCompany;
            },
            set(value) {
                this.$root.setActiveCompany(value);
            }
        },

        loadingAuthenticatedUser() {
            return this.$root.loadingAuthenticatedUser;
        }
    },

    watch: {
        parentCompanies: {
            handler() {
                this.$nextTick(() => {
                    if (!this.activeCompany || !_.find(this.activeCompanies, {id: this.activeCompany.id})) {
                        this.activeCompany = _.first(this.parentCompanies) || this.activeCompany;
                    }
                });
            },
            immediate: true,
        }
    },

    mounted() {

    },

    methods: {
        selectCompany(company) {
            this.activeCompany = company;

            this.showCompanyDialog = false;
        },

        companyIsActive(company) {
            return this.activeCompany && this.activeCompany.id == company.id;
        },

    }
}
</script>
