<template>

    <v-card class="text-center" elevation="3" outlined width="600">
        <template v-if="linkSent">
            <v-card-title class="justify-center primary--text">
                {{ $t('auth.static.checkEmail') }}
            </v-card-title>
            <v-card-subtitle>
                {{ $t('auth.static.passwordResetLinkSentFollowup') }}
            </v-card-subtitle>

            <v-card-text>
                <v-btn
                    block
                    color="accent"
                    to="login"
                >
                    {{ $t('auth.actions.backToLogin') }}
                </v-btn>
            </v-card-text>
        </template>

        <template v-else>
            <v-card-title class="justify-center primary--text">
                {{ $t('auth.static.forgotPassword') }}
            </v-card-title>
            <v-card-subtitle>
                {{ $t('auth.static.forgotPasswordDescription') }}
            </v-card-subtitle>

            <v-card-text>
                <v-text-field
                    id="email"
                    v-model="email"
                    :error-messages="validationErrors['email']"
                    :label="$t('auth.static.email')"
                    autofocus
                    dense
                    outlined
                    @keydown.enter="sendLink"
                ></v-text-field>

                <v-btn
                    :loading="sendingLink"
                    block
                    class="mb-3"
                    color="primary"
                    @click="sendLink"
                >
                    {{ $t('auth.actions.sendPasswordResetLink') }}
                </v-btn>

                <v-btn
                    color="accent"
                    text
                    to="login"
                >
                    {{ $t('auth.actions.backToLogin') }}
                </v-btn>
            </v-card-text>
        </template>
    </v-card>

</template>

<script>
export default {
    data() {
        return {
            email: null,

            validationErrors: {},

            sendingLink: false,

            linkSent: false,
        }
    },

    methods: {
        sendLink() {
            this.sendingLink = true;

            axios.post(`/api/auth/password-reset/send-link?locale=${this.$i18n.locale}`, {email: this.email})
                .then(() => {
                    this.$root.showSnackbar(this.$t('auth.static.passwordResetLinkSent'), 'success');

                    this.$router.replace({name: 'Login'});
                })
                .catch((error) => {
                    if (error.response.status == 422) {
                        this.validationErrors = error.response.data.errors;
                    } else {
                        this.$root.showSnackbar(this.$t('auth.static.passwordResetLinkSendFailed'), 'error');
                    }
                })
                .finally(() => {
                    this.sendingLink = false;
                });
        }
    }
}
</script>
