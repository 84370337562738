<template>

    <div>
        <v-overlay :value="! isInitialized">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>

        <router-view v-if="isInitialized"/>
    </div>

</template>

<script>
export default {
    computed: {
        isInitialized() {
            return !this.$root.initializing;
        },
    }
}
</script>
