<script>
import {Doughnut, mixins} from 'vue-chartjs';

const {reactiveProp} = mixins;

export default {
    name: 'PieChart',

    extends: Doughnut,
    mixins: [reactiveProp],

    data() {
        return {};
    },

    computed: {
        options() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    position: this.$vuetify.breakpoint.xsOnly ? 'top' : 'left',
                    align: this.$vuetify.breakpoint.xsOnly ? 'center' : 'end',
                },
                cutoutPercentage: 80,
            };
        },
    },

    watch: {
        '$i18n.locale'() {
            this.refresh();
        },
    },

    mounted() {
        this.renderChart(this.chartData, this.options);
    },

    methods: {
        refresh() {
            this.$data._chart.update();
        },
    },
};
</script>
