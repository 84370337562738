<template>

    <v-app>
        <v-app-bar app class="d-print-none" color="white" elevation="1">
            <v-container :class="$vuetify.breakpoint.mdAndDown ? 'pa-0' : 'py-0'" :fluid="$vuetify.breakpoint.mdAndDown"
                         class="fill-height">
                <router-link :to="{ name: 'Home' }">
                    <v-img
                        :max-width="$vuetify.breakpoint.xsOnly ? 150 : 175"
                        :src="require('@/img/hy-check-logo.svg')"
                        class="cursor-pointer"
                        contain
                        height="100%"
                    ></v-img>
                </router-link>

                <v-spacer></v-spacer>

                <div class="flex-nowrap">
                    <language-select
                        v-if="user"
                        v-model="user.language_code"
                        :btn-classes="$vuetify.breakpoint.smOnly ? 'mr-1' : 'mr-3'"
                        :loading="savingLanguage"
                        @input="saveLanguage"
                    ></language-select>

                    <template v-if="$vuetify.breakpoint.smAndDown">
                        <v-menu bottom left offset-y>
                            <template v-slot:activator="{ on }">
                                <v-btn :small="$vuetify.breakpoint.xsOnly" icon v-on="on">
                                    <v-icon>
                                        mdi-menu
                                    </v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item v-if="$route.name != 'Settings'" :to="{ name: 'Settings' }">
                                    <v-list-item-title>{{ $t('general.actions.myHyCheck') }}</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-else :to="{ name: 'Home' }">
                                    <v-list-item-title>{{ $t('general.actions.dashboard') }}</v-list-item-title>
                                </v-list-item>
                                <template v-if="isMobile">
                                    <v-divider/>
                                    <v-list-item @click="openMobileApp">
                                        <v-list-item-content>
                                            <img v-if="isAndroid" src="/img/google-play-badge.svg" width="200px"/>
                                            <img v-if="isIOS" src="/img/app-store-badge.svg" width="200px"/>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-menu>
                    </template>
                    <template v-else>
                        <v-btn
                            v-if="$route.name != 'Settings'"
                            :to="{ name: 'Settings' }"
                            color="primary"
                            elevation="0"
                        >
                            {{ $t('general.actions.myHyCheck') }}
                        </v-btn>
                        <v-btn
                            v-else
                            :to="{ name: 'Home' }"
                            color="primary"
                            elevation="0"
                        >
                            {{ $t('general.actions.dashboard') }}
                        </v-btn>
                    </template>
                </div>
            </v-container>
        </v-app-bar>

        <v-overlay
            :opacity="0.3"
            :value="true"
            color="white"
            z-index="0"
        ></v-overlay>

        <v-main>
            <v-container v-if="user && user.is_impersonated" class="mb-0">
                <v-alert
                    border="left"
                    class="mb-0"
                    elevation="1"
                    icon="mdi-shield-account"
                    type="info"
                >
                    {{ $t('auth.static.impersonatingDescription', {name: user.name}) }}
                </v-alert>
            </v-container>

            <v-container v-if="user && activeCompanies.length <= 0" class="mb-0">
                <v-alert
                    border="left"
                    class="mb-0"
                    elevation="1"
                    icon="mdi-alert"
                    type="warning"
                >
                    {{ $t('auth.static.noActiveCompaniesWarning') }}
                </v-alert>
            </v-container>

            <router-view></router-view>
        </v-main>

        <snackbar
            v-model="$root.snackbar.text"
            :type="$root.snackbar.type"
        ></snackbar>
    </v-app>

</template>

<style>
.v-app-bar .v-toolbar__content {
    padding: 4px 12px;
}
</style>

<script>
import Snackbar from '@/js/components/Snackbar';
import LanguageSelect from '@/js/components/LanguageSelect';

import UserAgentMixin from '@/js/mixins/userAgent';
import MobileMixin from '@/js/mixins/mobile';

export default {
    components: {Snackbar, LanguageSelect},

    mixins: [UserAgentMixin, MobileMixin],

    data() {
        return {
            loggingOut: false,

            savingLanguage: false,
        }
    },

    computed: {
        companies() {
            if (!this.user) return [];

            return this.user.companies;
        },

        activeCompanies() {
            if (this.user && this.user.isAdmin) return this.companies;

            return _.filter(this.companies, (company) => {
                return company.isActive;
            });
        },

        user() {
            return this.$root.user;
        },

        activeCompany: {
            get() {
                return this.$root.activeCompany;
            },
            set(value) {
                this.$root.setActiveCompany(value);
            }
        },

        loadingAuthenticatedUser() {
            return this.$root.loadingAuthenticatedUser;
        }
    },

    methods: {
        saveLanguage() {
            this.savingLanguage = true;

            axios.put(`/api/users/${this.user.id}`, this.user)
                .then(() => {
                    this.$root.setLocale(this.user.language_code);

                    this.$root.showSnackbar(this.$t('settings.static.languageSaved'), 'success');
                })
                .catch((error) => {
                    if (error.response.status == 422) {
                        this.validationErrors = error.response.data.errors;
                    }
                })
                .finally(() => {
                    this.savingLanguage = false;
                });
        },
    }
}
</script>
