<template>

    <v-app>
        <v-app-bar app elevation="1">
            <v-img
                :src="require('@/img/hy-check-logo.svg')"
                contain
                height="100%"
                position="center center"
            ></v-img>
        </v-app-bar>

        <v-main>
            <v-overlay
                :value="true"
                color="white"
                z-index="0"
            ></v-overlay>

            <v-container fill-height fluid>
                <router-view></router-view>
            </v-container>
        </v-main>

        <snackbar
            v-model="$root.snackbar.text"
            :type="$root.snackbar.type"
        ></snackbar>
    </v-app>

</template>

<script>
import Snackbar from '@/js/components/Snackbar';

export default {
    components: {Snackbar},
}
</script>
