const hoofPositionMixin = {
    computed: {
        hoofPositionIcons() {
            return {
                0: 'mdi-arrow-top-left',
                1: 'mdi-arrow-top-right',
                2: 'mdi-arrow-bottom-right',
                3: 'mdi-arrow-bottom-left',
            };
        },

        hoofPositionLabels() {
            return {
                0: this.$t('hoofcare.static.leftFront'),
                1: this.$t('hoofcare.static.rightFront'),
                2: this.$t('hoofcare.static.rightRear'),
                3: this.$t('hoofcare.static.leftRear'),
            };
        },
    },
}

export default hoofPositionMixin;
