<template>

    <v-menu v-model="show" :close-on-content-click="false" :top="top" max-width="400" offset-y>
        <template v-slot:activator="{ on }">
            <v-text-field
                :disabled="disabled"
                :error-messages="errorMessages"
                :label="label"
                :value="formatDate"
                append-icon="mdi-calendar"
                dense
                outlined
                @change="parseManualInput"
                @click:clear="input(null)"
                @click:append="show = ! show"
            ></v-text-field>
        </template>

        <v-date-picker
            v-model="date"
            :disabled="disabled"
            class="elevation-0"
            first-day-of-week="1"
            full-width
            show-week
            @change="setValue"
        ></v-date-picker>
    </v-menu>

</template>

<style>
.v-date-picker-title {
    height: 70px;
}
</style>

<script>
export default {
    props: {
        value: {
            default: null
        },

        label: {
            default: ''
        },

        errorMessages: {
            default: () => []
        },

        hideDetails: {
            default: false
        },

        top: {
            default: false
        },

        disabled: {
            default: false
        },
    },

    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.date = moment(value || undefined).format('YYYY-MM-DD');
            }
        }
    },

    computed: {
        formatDate() {
            if (this.date.length <= 0) return null;

            return moment(this.date).format('DD-MM-YYYY');
        }
    },

    data() {
        return {
            show: false,

            date: null,
        }
    },

    methods: {
        setValue() {
            this.input(this.date);

            this.show = false;
        },

        parseManualInput(value) {
            let date = moment(value, 'DD-MM-YYYY');

            if (!date.isValid()) return;

            this.date = date.format('YYYY-MM-DD');

            this.input(this.date);
        },

        input(value) {
            this.$emit('input', value);
        }
    }
}
</script>
