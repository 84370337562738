<template>

    <v-card>
        <v-list>
            <v-list-item
                v-for="(item, index) in items"
                :key="index"
                @click=""
            >
                <v-list-item-icon>
                    <v-icon color="primary" large>
                        {{ item.icon }}
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    {{ item.label }}
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-card>

</template>

<script>
export default {
    computed: {
        items() {
            return [
                {
                    icon: 'mdi-file-document-outline',
                    label: this.$t('settings.actions.howToUseApp')
                },
                {
                    icon: 'mdi-youtube',
                    label: this.$t('settings.actions.videoTutorial')
                }
            ];
        }
    }
}
</script>
