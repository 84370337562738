<template>

    <div>
        <line-chart
            :chart-data="{ labels: labels, datasets: datasets }"
            :chart-id="id"
            :height="height"
            :max="100"
            :min="0"
        ></line-chart>
    </div>

</template>

<script>
import LineChart from '@/js/components/charts/LineChart';

export default {
    components: {LineChart},

    props: {
        id: String,
        statistics: Object,
        cowsCount: {
            default: 0
        },
        height: Number
    },

    computed: {
        datasets() {
            if (!this.statistics) return [];

            let i = 0;

            return _.map(this.statistics.trend.years, (months, year) => {
                let values = _.map(months, (score) => {
                    if (score === null) return null;

                    return Math.floor(100 - (((score / this.cowsCount) * 10000) / 100));
                });

                return {
                    label: year,
                    data: values,
                    backgroundColor: 'transparent',
                    borderColor: this.chartColors[i++],
                    pointBackgroundColor: '#ffffff',
                    borderWidth: 2,
                    lineTension: 0
                }
            });
        },

        chartColors() {
            return _.clone(window.Config.charts.trendline.colors).reverse();
        },

        labels() {
            const labels = [];

            for (let i = 0; i < 12; i++) {
                labels.push(i);
            }

            return labels;
        },
    },
}
</script>
