import Vue from 'vue';
import Router from 'vue-router';
import LayoutAuth from '@/js/layouts/Auth';
import LayoutClean from '@/js/layouts/Clean';
import LayoutDashboard from '@/js/layouts/Dashboard';

import PageAuthLogin from '@/js/pages/auth/Login';
import PageAuthForgotPassword from '@/js/pages/auth/ForgotPassword';
import PageAuthResetPassword from '@/js/pages/auth/ResetPassword';
import PageAuthRegister from '@/js/pages/auth/Register';

import Page404 from '@/js/pages/404';
import PageHome from '@/js/pages/Home';
import PageSettings from '@/js/pages/Settings';

import PageHoofcare from '@/js/pages/modules/Hoofcare';
import PageHoofcareDetails from '@/js/pages/modules/hoofcare/Details';
import PageHoofcarePhotos from '@/js/pages/modules/hoofcare/Photos';
import PageCleaningPigs from '@/js/pages/modules/CleaningPigs';
import PageCleaningPigsLocations from '@/js/pages/modules/cleaning-pigs/Locations';
import PageCleaningPigsLocationDetails from '@/js/pages/modules/cleaning-pigs/location/Details';
import PageCleaningPigsLocationPhotos from '@/js/pages/modules/cleaning-pigs/location/Photos';
import PageCleaningPoultry from '@/js/pages/modules/CleaningPoultry';
import PageCleaningPoultryLocations from '@/js/pages/modules/cleaning-poultry/Locations';
import PageCleaningPoultryLocationDetails from '@/js/pages/modules/cleaning-poultry/location/Details';
import PageCleaningPoultryLocationPhotos from '@/js/pages/modules/cleaning-poultry/location/Photos';
import PageWaterSolutions from '@/js/pages/modules/WaterSolutions';
import PageWaterSolutionsLocations from '@/js/pages/modules/water-solutions/Locations';
import PageWaterSolutionsLocationDetails from '@/js/pages/modules/water-solutions/location/Details';
import PageWaterSolutionsLocationPhotos from '@/js/pages/modules/water-solutions/location/Photos';

Vue.use(Router);


export default new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '',
            component: LayoutDashboard,
            children: [
                {
                    name: 'Home',
                    path: '/',
                    component: PageHome,
                    meta: {
                        requiresAuth: true,
                        title: 'Dashboard'
                    }
                },
                {
                    name: 'Settings',
                    path: '/settings',
                    component: PageSettings,
                    meta: {
                        requiresAuth: true,
                        title: 'Settings'
                    }
                },
                {
                    name: 'Hoofcare',
                    path: '/hoofcare',
                    component: PageHoofcare,
                    meta: {
                        requiresAuth: true,
                        title: 'Hoofcare'
                    }
                },
                {
                    name: 'HoofcareDetails',
                    path: '/hoofcare/:companyId',
                    component: PageHoofcareDetails,
                    props: route => ({companyId: _.toNumber(route.params.companyId)}),
                    meta: {
                        requiresAuth: true,
                        title: 'Hoofcare details'
                    }
                },
                {
                    name: 'HoofcarePhotos',
                    path: '/hoofcare/:companyId/photos',
                    component: PageHoofcarePhotos,
                    props: route => ({companyId: _.toNumber(route.params.companyId)}),
                    meta: {
                        requiresAuth: true,
                        title: 'Hoofcare photos'
                    }
                },
                {
                    name: 'CleaningPigs',
                    path: '/cleaning-pigs',
                    component: PageCleaningPigs,
                    meta: {
                        requiresAuth: true,
                        title: 'Cleaning & disinfection pigs'
                    }
                },
                {
                    name: 'CleaningPigsLocations',
                    path: '/cleaning-pigs/:companyId',
                    component: PageCleaningPigsLocations,
                    props: route => ({companyId: _.toNumber(route.params.companyId)}),
                    meta: {
                        requiresAuth: true,
                        title: 'Cleaning & disinfection pigs locations'
                    }
                },
                {
                    name: 'CleaningPigsLocationDetails',
                    path: '/cleaning-pigs/:companyId/locations/:locationId',
                    component: PageCleaningPigsLocationDetails,
                    props: route => ({
                        companyId: _.toNumber(route.params.companyId),
                        locationId: _.toNumber(route.params.locationId)
                    }),
                    meta: {
                        requiresAuth: true,
                        title: 'Cleaning & disinfection pigs location'
                    }
                },
                {
                    name: 'CleaningPigsLocationPhotos',
                    path: '/cleaning-pigs/:companyId/locations/:locationId/photos',
                    component: PageCleaningPigsLocationPhotos,
                    props: route => ({
                        companyId: _.toNumber(route.params.companyId),
                        locationId: _.toNumber(route.params.locationId)
                    }),
                    meta: {
                        requiresAuth: true,
                        title: 'Cleaning & disinfection pigs photos'
                    }
                },
                {
                    name: 'CleaningPoultry',
                    path: '/cleaning-poultry',
                    component: PageCleaningPoultry,
                    meta: {
                        requiresAuth: true,
                        title: 'Cleaning & disinfection poultry'
                    },
                },
                {
                    name: 'CleaningPoultryLocations',
                    path: '/cleaning-poultry/:companyId',
                    component: PageCleaningPoultryLocations,
                    props: route => ({companyId: _.toNumber(route.params.companyId)}),
                    meta: {
                        requiresAuth: true,
                        title: 'Cleaning & disinfection poultry locations'
                    }
                },
                {
                    name: 'CleaningPoultryLocationDetails',
                    path: '/cleaning-poultry/:companyId/locations/:locationId',
                    component: PageCleaningPoultryLocationDetails,
                    props: route => ({
                        companyId: _.toNumber(route.params.companyId),
                        locationId: _.toNumber(route.params.locationId)
                    }),
                    meta: {
                        requiresAuth: true,
                        title: 'Cleaning & disinfection poultry location'
                    }
                },
                {
                    name: 'CleaningPoultryLocationPhotos',
                    path: '/cleaning-poultry/:companyId/locations/:locationId/photos',
                    component: PageCleaningPoultryLocationPhotos,
                    props: route => ({
                        companyId: _.toNumber(route.params.companyId),
                        locationId: _.toNumber(route.params.locationId)
                    }),
                    meta: {
                        requiresAuth: true,
                        title: 'Cleaning & disinfection poultry photos'
                    }
                },
                {
                    name: 'WaterSolutions',
                    path: '/water-solutions',
                    component: PageWaterSolutions,
                    meta: {
                        requiresAuth: true,
                        title: 'Water solutions'
                    }
                },
                {
                    name: 'WaterSolutionsLocations',
                    path: '/water-solutions/:companyId',
                    component: PageWaterSolutionsLocations,
                    props: route => ({companyId: _.toNumber(route.params.companyId)}),
                    meta: {
                        requiresAuth: true,
                        title: 'Water solutions locations'
                    }
                },
                {
                    name: 'WaterSolutionsLocationDetails',
                    path: '/water-solutions/:companyId/locations/:locationId',
                    component: PageWaterSolutionsLocationDetails,
                    props: route => ({
                        companyId: _.toNumber(route.params.companyId),
                        locationId: _.toNumber(route.params.locationId)
                    }),
                    meta: {
                        requiresAuth: true,
                        title: 'Water solutions location'
                    }
                },
                {
                    name: 'WaterSolutionsLocationPhotos',
                    path: '/water-solutions/:companyId/locations/:locationId/photos',
                    component: PageWaterSolutionsLocationPhotos,
                    props: route => ({
                        companyId: _.toNumber(route.params.companyId),
                        locationId: _.toNumber(route.params.locationId)
                    }),
                    meta: {
                        requiresAuth: true,
                        title: 'Water solutions photos'
                    }
                },
            ]
        },
        {
            path: '/auth',
            component: LayoutAuth,
            children: [
                {
                    name: 'Login',
                    path: 'login',
                    component: PageAuthLogin,
                    meta: {
                        guest: true,
                        title: 'Login'
                    }
                },
                {
                    name: 'Forgot password',
                    path: 'forgot-password',
                    component: PageAuthForgotPassword,
                    meta: {
                        guest: true,
                        title: 'Forgot password'
                    }
                },
                {
                    name: 'Reset password',
                    path: 'reset-password',
                    component: PageAuthResetPassword,
                    meta: {
                        guest: true,
                        title: 'Reset password'
                    }
                },
                {
                    name: 'Register',
                    path: 'register',
                    component: PageAuthRegister,
                    meta: {
                        guest: true,
                        title: 'Register'
                    }
                },
            ],
        },
        {
            path: '*',
            component: LayoutClean,
            children: [
                {
                    name: '404',
                    path: '*',
                    component: Page404,
                    meta: {
                        title: '404'
                    }
                }
            ]
        },
    ],
})
