<template>

    <v-card>
        <v-overlay :value="loading" absolute color="white"></v-overlay>

        <v-card-text>
            <v-row>
                <v-col class="shrink">
                    <v-btn-toggle v-model="selectedModules" borderless color="primary" dense multiple>
                        <v-btn v-for="module in $root.modules" :key="module.slug" :value="module.slug"
                               style="height:40px">
                            {{ module.name }}
                        </v-btn>
                    </v-btn-toggle>
                </v-col>
                <v-col class="grow">
                    <v-text-field
                        v-model="search"
                        :label="$t('general.static.search')"
                        append-icon="mdi-magnify"
                        color="primary"
                        dense
                        hide-details
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-data-table
            :footer-props="{ itemsPerPageOptions: [10, 25, 50] }"
            :headers="headers"
            :items="companies"
            :loading="loading"
            :options.sync="dataTableOptions"
            :server-items-length="totalCompanies"
        >
            <template v-slot:item.trial_ends_at="{ item }">
                <div v-if="item.is_activated" class="grey--text">
                    <v-icon color="success">
                        mdi-check
                    </v-icon>
                    {{ $t('settings.static.activated') }}
                </div>
                <div v-else-if="! item.hasTrialEnded">
                    {{ $t('settings.static.trialUntil', {date: $moment(item.trial_ends_at).format('DD-MM-YYYY')}) }}
                </div>
                <div v-else class="grey--text">
                    <v-icon color="error">
                        mdi-close
                    </v-icon>
                    {{
                        item.trial_ends_at ? $t('settings.static.inactiveSince', {date: $moment(item.trial_ends_at).format('DD-MM-YYYY')}) : $t('general.static.inactive')
                    }}
                </div>
            </template>

            <template v-slot:item.users_count="{ item }">
                <div :class="item.users_count > 0 ? '' : 'error--text'">
                    {{ item.users_count }}
                </div>
            </template>

            <template v-slot:item.address="{ item }">
                {{ item.address ? item.address.asString : '' }}
            </template>

            <template v-slot:item.actions="{ item }">
                <div class="text-no-wrap">
                    <v-icon
                        class="mr-2"
                        small
                        @click="editCompany(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="confirmDeleteCompany(item)"
                    >
                        mdi-delete
                    </v-icon>
                </div>
            </template>
        </v-data-table>

        <v-divider></v-divider>

        <v-card-actions class="pa-4">
            <v-spacer></v-spacer>

            <v-btn color="primary" @click="makeCompany">
                {{ $t('settings.actions.addCompany') }}
            </v-btn>
        </v-card-actions>

        <company-dialog
            v-model="showCompanyDialog"
            :company="selectedCompany"
            @created="load"
            @updated="$root.loadAuthenticatedUser(true);load()"
        ></company-dialog>

        <confirm-delete-dialog
            v-model="showConfirmDeleteDialog"
            :company="selectedCompany"
            @deleted="$root.loadAuthenticatedUser(true);load()"
        ></confirm-delete-dialog>
    </v-card>

</template>

<script>
import CompanyDialog from './companies/CompanyDialog';
import ConfirmDeleteDialog from './companies/ConfirmDeleteDialog';

export default {
    components: {CompanyDialog, ConfirmDeleteDialog},

    data() {
        return {
            companies: [],
            totalCompanies: 0,

            selectedModules: [],

            loading: false,

            dataTableOptions: {
                sortBy: ['name'],
                sortDesc: [false],
                multiSort: false,
                page: 1,
                itemsPerPage: 25
            },

            search: null,
            searchDebounced: null,
            searchColumns: [
                'name',
                'email',
                'phone',
                'address.street',
                'address.street_number',
                'address.zip_code',
                'address.city',
            ],

            selectedCompany: null,

            showCompanyDialog: false,
            showConfirmDeleteDialog: false,
        }
    },

    computed: {
        headers() {
            return [
                {text: this.$t('settings.static.id'), value: 'id'},
                {text: this.$t('settings.static.name'), value: 'name'},
                {text: this.$t('settings.static.address'), value: 'address'},
                {text: this.$t('settings.static.users'), value: 'users_count'},
                {text: this.$t('general.static.active'), value: 'trial_ends_at'},
                {text: this.$t('settings.static.parentCompany'), value: 'parent.name'},
                {value: 'actions', align: 'right', sortable: false},
            ];
        },

        queryParameters() {
            let {sortBy, sortDesc, page, itemsPerPage} = this.dataTableOptions;

            sortBy = _.first(sortBy);
            sortDesc = _.first(sortDesc);

            let parameters = [
                `with=[parent,users,address,modules]`,
                `count=[users]`,
                `appends=[isActive,hasTrialEnded]`,
                `paginate=${itemsPerPage}`,
                `page=${page}`,
                ...(this.searchDebounced ? [
                    `search_columns=[${this.searchColumns.join(',')}]`,
                    `search_value=${this.searchDebounced}`,
                ] : [])
            ];

            let scopes = [];

            if (!_.isEmpty(this.selectedModules)) {
                scopes.push(`hasModules|${_.join(this.selectedModules, ':')}`);
            }

            if (sortBy == 'parent.name') {
                scopes.push(`orderByParent|${sortDesc ? 'desc' : 'asc'}`);
            } else if (sortBy == 'address') {
                scopes.push(`orderByAddress|${sortDesc ? 'desc' : 'asc'}`);
            } else if (sortBy == 'trial_ends_at') {
                scopes.push(`orderByTrialEndsAt|${sortDesc ? 'desc' : 'asc'}`);
            } else {
                parameters.push(...[
                    `order_by=${sortBy || 'id'}`,
                    `order=${sortDesc ? 'desc' : 'asc'}`,
                ]);
            }

            parameters.push(`scopes=${_.join(scopes, ',')}`);

            return parameters;
        }
    },

    watch: {
        queryParameters: {
            handler() {
                this.load();
            },
            deep: true
        },

        search: _.debounce(function () {
            this.searchDebounced = this.search;
        }, 500),
    },

    created() {
        this.selectedModules = _.map(this.$root.modules, (module) => module.slug);
    },

    methods: {
        makeCompany() {
            this.selectedCompany = null;
            this.showCompanyDialog = true;
        },

        editCompany(company) {
            this.selectedCompany = company;
            this.showCompanyDialog = true;
        },

        confirmDeleteCompany(company) {
            this.selectedCompany = company;
            this.showConfirmDeleteDialog = true;
        },

        load() {
            this.loading = true;

            axios.get(`/api/companies?${_.join(this.queryParameters, '&')}`)
                .then((response) => {
                    this.companies = response.data.items;
                    this.totalCompanies = response.data.total;
                })
                .catch(() => {

                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
}
</script>
