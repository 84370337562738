<template>

    <v-autocomplete
        :error-messages="validationErrors"
        :items="users"
        :label="label || $t('general.static.users')"
        :loading="loading"
        :multiple="multiple"
        :value="value"
        dense
        hide-selected
        item-text="name"
        item-value="id"
        outlined
        @input="$emit('input', $event)"
    >
        <template v-slot:selection="data">
            <v-chip
                :input-value="data.selected"
                close
                color="primary"
                small
                v-bind="data.attrs"
                @click="data.select"
                @click:close="deselect(data.item)"
            >
                {{ data.item.name }}
            </v-chip>
        </template>

        <template v-slot:item="{ item }">
            <v-list-item-content>
                <v-list-item-title>
                    {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{ item.email }}
                </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-icon v-if="! item.activated_at" class="caption grey--text">
                {{ $t('general.static.inactive') }}
            </v-list-item-icon>
        </template>
    </v-autocomplete>

</template>

<script>
export default {
    props: {
        value: Array,
        validationErrors: Array,
        multiple: Boolean,
        label: String,
    },

    data() {
        return {
            users: [],

            loading: false,
        }
    },

    mounted() {
        this.load();
    },

    methods: {
        load() {
            this.loading = true;

            axios.get(`/api/users?order_by=first_name&order=asc`)
                .then((response) => {
                    this.users = response.data.items;
                })
                .catch(() => {
                    this.$root.showSnackbar(this.$t('settings.static.loadUsersFailed'), 'error');
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        deselect(user) {
            this.$emit('input', _.filter(this.value, (id) => id != user.id));
        },
    },
}
</script>
