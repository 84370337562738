<template>

    <v-card class="fill-height">
        <v-overlay :value="loading" absolute color="white"></v-overlay>

        <v-card-title class="title primary--text">
            {{ $t('cleaningAndDisinfection.static.overview') }}

            <v-spacer/>

            <small v-if="location && location.cleaningPigsStatistics && location.cleaningPigsStatistics.updatedAt"
                   class="caption grey--text font-weight-regular">
                {{
                    $t('general.static.lastCheckedAt', {date: $moment(location.cleaningPigsStatistics.updatedAt).format('DD-MM-YYYY HH:mm')})
                }}
            </small>
        </v-card-title>

        <v-card-text>
            <v-row>
                <v-col cols="6">
                    <v-sheet class="text-center d-flex flex-column justify-space-between" height="112" outlined>
                        <v-icon
                            :color="location ? checkpointStatusColors[getStatusForScore(location.cleaningPigsStatistics.currentScore) || 0] : 'secondary'"
                            class="pt-3"
                            x-large
                        >
                            mdi-circle
                        </v-icon>

                        <v-card-subtitle>
                            {{ $t('general.static.status') }}
                        </v-card-subtitle>
                    </v-sheet>
                </v-col>

                <v-col cols="6">
                    <v-sheet class="text-center d-flex flex-column justify-space-between" height="112" outlined>
                        <v-card-subtitle class="display-1 font-weight-medium pb-0" color="secondary">
                            {{
                                location && location.cleaningPigsStatistics.currentScore !== null ? $_.round(location.cleaningPigsStatistics.currentScore, 2) : '-'
                            }}
                        </v-card-subtitle>
                        <v-card-subtitle>
                            {{ $t('checkpoints.static.avgScore') }}
                        </v-card-subtitle>
                    </v-sheet>
                </v-col>
            </v-row>

            <v-list class="pt-3">
                <v-list-item v-for="status in [0,1,2,3]" :key="status" dense>
                    <v-list-item-icon>
                        <v-icon :color="checkpointStatusColors[status]">
                            mdi-circle
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        {{ checkpointStatusLongLabels[status] }}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>

</template>

<script>
import CheckpointStatusMixin from '@/js/mixins/checkpointStatus';

export default {
    mixins: [CheckpointStatusMixin],

    props: {
        location: Object,

        loading: Boolean
    },
}
</script>
