<template>

    <div>
        <v-dialog
            :value="value"
            max-width="460"
            @input="$emit('input', $event)"
        >
            <v-card>
                <v-card-title>
                    {{
                        exists ? $t('settings.static.editMortellaroScore') : $t('settings.static.createMortellaroScore')
                    }}

                    <v-spacer></v-spacer>

                    <small v-if="data && data.updated_at" class="caption grey--text font-weight-regular">
                        {{
                            $t('general.static.lastUpdatedAt', {date: $moment(data.updated_at).format('DD-MM-YYYY HH:mm')})
                        }}
                    </small>
                </v-card-title>

                <v-card-text v-if="data" class="pb-0">
                    <v-row align="center" class="mb-3">
                        <v-col cols="12" sm="4">
                            {{ $t('hoofcare.static.score') }}
                        </v-col>
                        <v-col class="text-right" cols="12" sm="8">
                            <v-btn-toggle
                                v-model="data.score"
                                color="primary"
                                dense
                                mandatory
                            >
                                <v-btn>
                                    0
                                </v-btn>

                                <v-btn>
                                    1
                                </v-btn>

                                <v-btn>
                                    2
                                </v-btn>

                                <v-btn>
                                    3
                                </v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>

                    <animal-select
                        v-model="data.animal_id"
                        :label="$t('general.static.animal')"
                        :validation-errors="validationErrors['animal_id']"
                    ></animal-select>

                    <date-time-picker
                        v-model="data.saved_at"
                        :label="$t('general.static.createdAt', {date: ''})"
                    ></date-time-picker>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions v-if="data" class="px-6 py-5">
                    <v-spacer></v-spacer>

                    <v-btn text @click="$emit('input', false)">
                        {{ $t('general.actions.cancel') }}
                    </v-btn>

                    <v-btn
                        :loading="saving"
                        color="primary"
                        @click="save"
                    >
                        {{ exists ? $t('general.actions.save') : $t('general.actions.create') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
import AnimalSelect from '@/js/components/AnimalSelect';
import DateTimePicker from '@/js/components/DateTimePicker';

export default {
    components: {AnimalSelect, DateTimePicker},

    props: {
        value: Boolean,
        mortellaroScore: Object,
    },

    data() {
        return {
            data: null,

            saving: false,

            validationErrors: {},
        }
    },

    computed: {
        activeCompany() {
            return this.$root.activeCompany;
        },

        exists() {
            return !this.data || !!this.data.id;
        },

        changedCompany() {
            if (!this.mortellaroScore) return true;

            return this.mortellaroScore.company_id != this.data.company_id;
        },
    },

    watch: {
        value() {
            this.data = {
                id: null,
                score: null,
                animal_id: null,
                saved_at: null,
                ..._.cloneDeep(this.mortellaroScore)
            };

            this.data.file_path = null;

            this.validationErrors = {};
        },
    },

    methods: {
        async save() {
            this.saving = true;

            let request = this.exists
                ? this.update()
                : this.create();

            request.then((response) => {
                let message = this.exists
                    ? this.$t('settings.static.mortellaroScoreUpdated')
                    : this.$t('settings.static.mortellaroScoreCreated');

                this.$root.showSnackbar(message, 'success');

                this.$emit(this.exists ? 'updated' : 'created');

                this.$emit('input', false);
            }).catch((error) => {
                if (error.response.status == 422) {
                    this.validationErrors = error.response.data.errors;
                }
            }).finally(() => {
                this.saving = false;
            });
        },

        create() {
            return axios.post(`/api/mortellaro-scores`, this.data);
        },

        update() {
            return axios.put(`/api/mortellaro-scores/${this.data.id}`, this.data);
        },
    }
}
</script>
