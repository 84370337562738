<script>
import {Line, mixins} from 'vue-chartjs';

const {reactiveProp} = mixins;

export default {
    name: 'LineChart',

    extends: Line,
    mixins: [reactiveProp],

    props: {
        min: {
            type: Number,
            default: 0,
        },

        max: {
            type: Number,
            default: 100,
        },

        animate: {
            type: Boolean,
            default: true,
        }
    },

    data() {
        return {};
    },

    computed: {
        options() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                spanGaps: true,

                animation: {
                    duration: this.animate ? 500 : 0,
                },

                legend: {
                    display: true,
                    align: this.$vuetify.breakpoint.mobile ? 'start' : 'center',
                    labels: {
                        filter: function (legendItem, chartData) {
                            return (chartData.datasets[legendItem.datasetIndex].label)
                        },
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            suggestedMin: this.min,
                            suggestedMax: this.max,
                        },
                    }],
                    xAxes: [{
                        ticks: {
                            callback: (value, index, values) => {
                                return this.getMonthName(value);
                            }
                        }
                    }],
                },
                tooltips: {
                    callbacks: {
                        title: (items, values) => {
                            return _.map(items, (item) => {
                                return this.getMonthName(item.label);
                            });
                        }
                    }
                }
            };
        },
    },

    watch: {
        '$i18n.locale'() {
            this.refresh();
        },
    },

    mounted() {
        this.renderChart(this.chartData, this.options);
    },

    methods: {
        refresh() {
            this.$data._chart.update();
        },

        getMonthName(index) {
            return moment().month(index).format('MMMM');
        },
    },
};
</script>
