const mobileMixin = {
    data() {
        return {
            openingMobileApp: false,
        };
    },

    methods: {
        openMobileApp() {
            if (!this.isMobile) return;

            if (!this.user) {
                if (this.isAndroid) {
                    window.location.replace(window.mobileStores.android);
                } else if (this.isIOS) {
                    window.location.replace(window.mobileStores.ios);
                }

                return;
            }

            this.openingMobileApp = true;

            axios.get(`/api/users/temporary-auth-urls`)
                .then((response) => {
                    if (this.isAndroid) {
                        window.location.replace(response.data.android_url);
                    } else if (this.isIOS) {
                        window.location.replace(response.data.ios_url);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.openingMobileApp = false;
                });
        },
    }
}

export default mobileMixin;
