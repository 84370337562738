<template>

    <v-list-item @click="$emit('click')">
        <v-list-item-action>
            <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title class="primary--text">
                {{ company.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
                {{ company.address.asString || $t('company.static.noAddress') }}
            </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action-text v-if="! company.isActive"
                                 :class="company && ! company.isActive ? 'warning--text' : 'primary--text'">
            {{ $t('general.static.inactive') }}
        </v-list-item-action-text>
    </v-list-item>

</template>

<script>
export default {
    props: {
        company: {
            type: Object,
            default: null
        },

        active: {
            type: Boolean,
            default: false
        }
    },
}
</script>
