<template>

    <v-dialog
        :value="value"
        scrollable
        @input="$emit('input', $event)"
    >
        <v-card outlined rounded>
            <v-card-title class="py-3 primary--text flex-nowrap align-start">
                <div class="d-flex flex-wrap">
                    {{ title }}

                    <small class="grey--text pl-3">
                        {{ subtitle }}
                    </small>
                </div>

                <v-spacer/>

                <v-btn
                    icon
                    @click="$emit('input', false)"
                >
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text v-if="note" class="py-0 grow">
                <p style="font-size: 14px; color: gray;">
                    {{ note }}
                </p>
            </v-card-text>

            <v-card-text class="pa-0">
                <v-img
                    :lazy-src="thumbnail"
                    :src="`${image}&${randomValue}`"
                    contain
                    max-height="100%"
                >
                    <template v-slot:placeholder>
                        <v-row
                            align="center"
                            class="fill-height ma-0"
                            justify="center"
                        >
                            <v-progress-circular
                                color="grey lighten-5"
                                indeterminate
                            ></v-progress-circular>
                        </v-row>
                    </template>

                    <div class="d-flex fill-height flex-column">
                        <div class="d-flex flex-grow-1 justify-space-between">
                            <div class="d-flex align-center">
                                <v-btn :disabled="! previousEnabled" class="ml-3 white" color="primary" fab
                                       icon @click="$emit('previous')">
                                    <v-icon x-large>
                                        mdi-chevron-left
                                    </v-icon>
                                </v-btn>
                            </div>
                            <div class="d-flex align-center">
                                <v-btn :disabled="! nextEnabled" class="mr-3 white" color="primary" fab icon
                                       @click="$emit('next')">
                                    <v-icon x-large>
                                        mdi-chevron-right
                                    </v-icon>
                                </v-btn>
                            </div>
                        </div>

                        <v-card-actions>
                            <v-spacer/>

                            <v-btn
                                :href="downloadUrl"
                                color="primary"
                                dark
                                fab
                                small
                            >
                                <v-icon dark>
                                    mdi-download
                                </v-icon>
                            </v-btn>
                        </v-card-actions>
                    </div>
                </v-img>
            </v-card-text>
        </v-card>
    </v-dialog>

</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },

        title: {
            type: String,
            default: null,
        },

        subtitle: {
            type: String,
            default: null,
        },

        note: {
            type: String,
            default: null,
        },

        thumbnail: {
            type: String,
            default: null,
        },

        image: {
            type: String,
            default: null,
        },

        downloadUrl: {
            type: String,
            default: null,
        },

        previousEnabled: {
            type: Boolean,
            default: false,
        },

        nextEnabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        randomValue() {
            if (!this.title || !this.subtitle) return;

            return (new Date()).getMilliseconds();
        }
    }
}
</script>
