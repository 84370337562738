<template>

    <v-autocomplete
        :error-messages="validationErrors"
        :items="roles"
        :label="$t('general.static.role')"
        :value="value"
        dense
        item-text="name"
        item-value="slug"
        outlined
        @input="$emit('input', $event)"
    ></v-autocomplete>

</template>

<script>
export default {
    props: {
        value: String,
        validationErrors: Array
    },

    computed: {
        roles() {
            return [
                {slug: 'admin', name: this.$t('general.static.administrator'),},
                {slug: 'user', name: this.$t('general.static.user'),},
            ];
        }
    },

}
</script>
