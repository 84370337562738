<template>

    <v-card>
        <v-card-title class="px-6 py-3 text-h4 primary--text">
            {{ animal.code }}
        </v-card-title>

        <v-card-text class="pa-6 pt-0">
            <div v-if="$_.isEmpty(animal.mortellaroScoreSummary)">
                {{ $t('hoofcare.static.lastUpdatedNever') }}
            </div>

            <v-row v-for="(hoofScores, hoofScoresIndex) in animal.mortellaroScoreSummary" v-else
                   :key="hoofScores.hoofPosition" class="justify-start">
                <v-col v-for="(propertyName, propertyIndex) in propertyNames" :key="propertyName" cols="12" sm="4">
                    <div class="text-h6 primary--text font-weight-regular"
                         v-html="hoofScores[propertyName] ? $moment(hoofScores[propertyName].saved_at).format('DD-MM-YYYY') : '&nbsp;'"/>

                    <span v-if="hoofScores[propertyName] && hoofScores[propertyName].note"
                          class="text-truncate-2 font-weight-normal mb-0">{{ hoofScores[propertyName].note }}</span>

                    <v-card v-if="hoofScores[propertyName]" :height="200"
                            color="secondary" outlined @click="selectScore(hoofScoresIndex, propertyIndex)">
                        <v-img
                            :src="hoofScores[propertyName] ? scoreImage(hoofScores[propertyName]) : null"
                            class="fill-height grey lighten-3"
                        >
                            <div class="d-flex fill-height flex-column">
                                <v-spacer/>

                                <div :class="$vuetify.breakpoint.xsOnly ? 'flex-wrap' : ''" class="d-flex"
                                     style="background-color: rgba(33,33,33,0.5)">
                                    <v-row no-gutters>
                                        <v-col cols="12" lg="4">
                                            <v-list-item dark dense>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>
                                                        {{ $t('hoofcare.static.hoof') }}
                                                    </v-list-item-subtitle>
                                                    <v-list-item-title>
                                                        {{ hoofPositionLabels[hoofScores.hoofPosition] }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="12" lg="4">
                                            <v-list-item dark dense>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>
                                                        {{ $t('general.static.time') }}
                                                    </v-list-item-subtitle>
                                                    <v-list-item-title>
                                                        {{ $moment(hoofScores[propertyName].saved_at).format('HH:mm') }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="12" lg="4">
                                            <v-list-item dark dense>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>
                                                        {{ $t('hoofcare.static.score') }}
                                                    </v-list-item-subtitle>
                                                    <v-list-item-title>
                                                        {{ hoofScores[propertyName].score }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>

        <PhotoDialog
            v-model="showPhotoDialog"
            :download-url="selectedData ? selectedData.imageDownloadUrl : null"
            :image="selectedData ? selectedData.image : null"
            :next-enabled="selectedData ? (!! nextScoreIndexes(selectedHoofScoresIndex, selectedPropertyIndex)) : false"
            :note="selectedData ? selectedData.note : null"
            :previous-enabled="selectedData ? (!! previousScoreIndexes(selectedHoofScoresIndex, selectedPropertyIndex)) : false"
            :subtitle="selectedData ? `${hoofPositionLabels[selectedData.hoof]}` : null"
            :thumbnail="selectedData ? selectedData.imageThumbnail : null"
            :title="selectedData ? selectedData.title : null"
            @next="selectNextScore"
            @previous="selectPreviousScore"
        />
    </v-card>

</template>

<script>
import PhotoDialog from '@/js/components/PhotoDialog';

import HoofPositionMixin from '@/js/mixins/hoofPosition';

export default {
    mixins: [HoofPositionMixin],

    components: {PhotoDialog},

    props: {
        date: {
            default: null
        },

        animal: {
            type: Object,
            default: []
        }
    },

    computed: {
        selectedSummary() {
            if (this.selectedHoofScoresIndex === null) return null;

            return this.animal.mortellaroScoreSummary[this.selectedHoofScoresIndex];
        },

        selectedScore() {
            if (!this.selectedSummary) return null;

            return this.selectedSummary[this.propertyNames[this.selectedPropertyIndex]];
        },

        selectedData() {
            if (!this.selectedScore) return null;

            return {
                title: moment(this.selectedScore.saved_at).format('DD-MM-YYYY HH:mm'),
                hoof: this.selectedScore.hoof_position,
                score: this.selectedScore.score,
                note: this.selectedScore.note,
                image: this.scoreImage(this.selectedScore, false),
                imageThumbnail: this.scoreImage(this.selectedScore),
                imageDownloadUrl: `${this.selectedScore.downloadUrls}&filename=${this.filename(this.selectedScore)}`,
            };
        }
    },

    data() {
        return {
            propertyNames: [
                'first',
                'second',
                'last',
            ],

            selectedHoofScoresIndex: null,
            selectedPropertyIndex: null,

            showPhotoDialog: false,
        }
    },

    methods: {
        filename(score) {
            return `${this.date ? this.date.format('DD-MM-YYYY') : ''} ${score.animal ? score.animal.code : '?'} ${this.hoofPositionLabels[score.hoof_position]}`;
        },

        scoreImage(score, thumbnail = true) {
            return thumbnail && score.base64Images ? `data:image/png;base64, ${score.base64Images}` : score.downloadUrls;
        },

        selectScore(hoofScoresIndex, propertyIndex) {
            this.selectedHoofScoresIndex = hoofScoresIndex;
            this.selectedPropertyIndex = propertyIndex;

            this.showPhotoDialog = true;
        },

        previousScoreIndexes(hoofScoresIndex, propertyIndex) {
            for (propertyIndex--; propertyIndex >= 0; propertyIndex--) {
                if (this.getScore(hoofScoresIndex, propertyIndex)) {
                    return {hoofScoresIndex: hoofScoresIndex, propertyIndex: propertyIndex};
                }
            }

            if (hoofScoresIndex <= 0) return null;

            return this.previousScoreIndexes(--hoofScoresIndex, this.propertyNames.length);
        },

        nextScoreIndexes(hoofScoresIndex, propertyIndex) {
            for (propertyIndex++; propertyIndex <= this.propertyNames.length - 1; propertyIndex++) {
                if (this.getScore(hoofScoresIndex, propertyIndex)) {
                    return {hoofScoresIndex: hoofScoresIndex, propertyIndex: propertyIndex};
                }
            }

            if (hoofScoresIndex >= this.animal.mortellaroScoreSummary.length - 1) return null;

            return this.nextScoreIndexes(++hoofScoresIndex, -1);
        },

        getScore(hoofScoresIndex, propertyIndex) {
            let summary = this.animal.mortellaroScoreSummary[hoofScoresIndex];

            if (!summary) return null;

            return this.selectedSummary[this.propertyNames[propertyIndex]];
        },

        selectPreviousScore() {
            let previousScoreIndexes = this.previousScoreIndexes(this.selectedHoofScoresIndex, this.selectedPropertyIndex);

            if (previousScoreIndexes) {
                this.selectedHoofScoresIndex = previousScoreIndexes.hoofScoresIndex;
                this.selectedPropertyIndex = previousScoreIndexes.propertyIndex;
            }
        },

        selectNextScore() {
            let nextScoreIndexes = this.nextScoreIndexes(this.selectedHoofScoresIndex, this.selectedPropertyIndex);

            if (nextScoreIndexes) {
                this.selectedHoofScoresIndex = nextScoreIndexes.hoofScoresIndex;
                this.selectedPropertyIndex = nextScoreIndexes.propertyIndex;
            }
        },
    },
}
</script>
