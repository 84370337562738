<template>

    <v-card>
        <v-card-text v-if="company" class="pb-0">
            <v-row>
                <v-col class="pb-0" cols="12" sm="8">
                    <v-text-field
                        v-model="company.name"
                        :error-messages="validationErrors['name']"
                        :label="$t('settings.static.companyName')"
                        color="primary"
                        dense
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col class="pb-0" cols="12" sm="4">
                    <v-text-field
                        v-model="company.reference"
                        :label="$t('settings.static.reference')"
                        color="primary"
                        dense
                        disabled
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col class="pb-0" cols="12" sm="8">
                    <v-text-field
                        v-model="company.address.street"
                        :error-messages="validationErrors['address.street']"
                        :label="$t('settings.static.address')"
                        color="primary"
                        dense
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col class="pb-0" cols="12" sm="4">
                    <v-text-field
                        v-model="company.address.street_number"
                        :error-messages="validationErrors['address.street_number']"
                        :label="$t('settings.static.addressNumber')"
                        color="primary"
                        dense
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col class="pb-0" cols="12" sm="4">
                    <v-text-field
                        v-model="company.address.zip_code"
                        :error-messages="validationErrors['address.zip_code']"
                        :label="$t('settings.static.zipCode')"
                        color="primary"
                        dense
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col class="pb-0" cols="12" sm="8">
                    <v-text-field
                        v-model="company.address.city"
                        :error-messages="validationErrors['address.city']"
                        :label="$t('settings.static.city')"
                        color="primary"
                        dense
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col class="pb-0" cols="12" sm="8">
                    <country-select
                        v-model="company.address.country"
                        :error-messages="validationErrors['country']"
                    ></country-select>
                </v-col>
            </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions v-if="company" class="pa-4">
            <v-spacer></v-spacer>

            <v-btn :loading="saving" color="primary" @click="save">
                {{ $t('settings.actions.update') }}
            </v-btn>
        </v-card-actions>
    </v-card>

</template>

<script>
import CountrySelect from '@/js/components/CountrySelect';

export default {
    components: {CountrySelect},

    data() {
        return {
            company: null,

            validationErrors: {},

            saving: false,
        }
    },

    computed: {
        activeCompany() {
            return this.$root.activeCompany;
        },
    },

    watch: {
        activeCompany: {
            handler(company) {
                if (!company) return;

                this.company = _.cloneDeep(company);
            },
            immediate: true
        }
    },

    methods: {
        save() {
            this.saving = true;

            axios.put(`/api/companies/${this.company.id}`, this.company)
                .then((response) => {
                    this.$root.loadAuthenticatedUser(true);

                    this.$root.showSnackbar(this.$t('settings.static.companyDetailsSaved'), 'success');
                })
                .catch((error) => {
                    if (error.response.status == 422) {
                        this.validationErrors = error.response.data.errors;
                    }
                })
                .finally(() => {
                    this.saving = false;
                });
        }
    }
}
</script>
