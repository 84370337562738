var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"value":_vm.value,"max-width":"700"},on:{"input":function($event){return _vm.$emit('input', $event)}}},[(_vm.value)?_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('hoofcare.static.hoofcare'))+" ")]),_c('v-data-table',{attrs:{"expanded":_vm.expandedItems,"footer-props":{ itemsPerPageOptions: [10, 25, 50] },"headers":_vm.headers,"items":_vm.mortellaroScores,"loading":_vm.loading,"options":_vm.dataTableOptions,"server-items-length":_vm.totalMortellaroScores,"show-expand":""},on:{"update:expanded":function($event){_vm.expandedItems=$event},"update:options":function($event){_vm.dataTableOptions=$event}},scopedSlots:_vm._u([{key:"item.saved_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.saved_at ? _vm.$moment(item.saved_at).format('DD-MM-YYYY HH:mm') : '')+" ")]}},{key:"item.hoof_position",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" "+_vm._s(_vm.hoofPositionIcons[item.hoof_position])+" ")]),_vm._v(" "+_vm._s(_vm.hoofPositionLabels[item.hoof_position])+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editMortellaroScore(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.confirmDeleteMortellaroScore(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":headers.length}},[_c('v-card',{attrs:{"href":item.downloadUrls,"color":"grey lighten-3","flat":"","height":"300","width":"700"}},[_c('v-img',{staticClass:"fill-height",attrs:{"src":item.base64Images ? ("data:image/png;base64, " + (item.base64Images)) : item.downloadUrls,"contain":""}})],1)],1)]}}],null,false,1280152691)}),_c('v-divider'),_c('v-card-actions',{staticClass:"px-6 py-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v(" "+_vm._s(_vm.$t('general.actions.close'))+" ")])],1)],1):_vm._e()],1),_c('mortellaro-score-dialog',{attrs:{"mortellaro-score":_vm.selectedMortellaroScore},on:{"created":_vm.load,"updated":_vm.load},model:{value:(_vm.showMortellaroScoreDialog),callback:function ($$v) {_vm.showMortellaroScoreDialog=$$v},expression:"showMortellaroScoreDialog"}}),_c('confirm-delete-mortellaro-score-dialog',{attrs:{"mortellaro-score":_vm.selectedMortellaroScore},on:{"deleted":function($event){return _vm.load()}},model:{value:(_vm.showConfirmDeleteDialog),callback:function ($$v) {_vm.showConfirmDeleteDialog=$$v},expression:"showConfirmDeleteDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }