<script>
import {Bar, mixins} from 'vue-chartjs';

const {reactiveProp} = mixins;

export default {
    name: 'BarChart',

    extends: Bar,
    mixins: [reactiveProp],

    props: {
        showDates: {
            type: Boolean,
            default: true,
        },

        showLegend: {
            type: Boolean,
            default: true,
        },

        min: {
            type: Number,
            default: 0,
        },

        max: {
            type: Number,
            default: 100,
        },
    },

    data() {
        return {};
    },

    computed: {
        options() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: this.showLegend ? {} : false,
                    align: this.$vuetify.breakpoint.mobile ? 'start' : 'center',
                },

                scales: {
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            suggestedMin: this.min,
                            suggestedMax: this.max,
                        },
                    }],
                    xAxes: [{
                        stacked: true,
                        ticks: {
                            callback: (value, index, values) => {
                                return this.showDates ? this.getMonthName(value) : value;
                            }
                        }
                    }],
                },
                tooltips: {
                    callbacks: {
                        title: (items, values) => {
                            return _.map(items, (item) => {
                                return this.showDates ? this.getMonthName(item.label) : item.label;
                            });
                        }
                    }
                }
            };
        },
    },

    watch: {
        '$i18n.locale'() {
            this.refresh();
        },
    },

    mounted() {
        this.renderChart(this.chartData, this.options);
    },

    methods: {
        refresh() {
            this.$data._chart.update();
        },

        getMonthName(index) {
            return moment().month(index).format('MMMM');
        },
    },
};
</script>
