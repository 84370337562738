<template>

    <div>
        <v-dialog
            :value="value"
            max-width="500"
            @input="$emit('input', $event)"
        >
            <v-card>
                <v-card-title>
                    {{ exists ? $t('settings.static.editAnimal') : $t('settings.static.createAnimal') }}

                    <v-spacer></v-spacer>

                    <small v-if="data && data.updated_at" class="caption grey--text font-weight-regular">
                        {{
                            $t('general.static.lastUpdatedAt', {date: $moment(data.updated_at).format('DD-MM-YYYY HH:mm')})
                        }}
                    </small>
                </v-card-title>

                <v-card-text v-if="data" class="pb-0">
                    <animal-type-select
                        v-model="data.animal_type_slug"
                        :validation-errors="validationErrors['animal_type_slug']"
                    ></animal-type-select>

                    <v-text-field
                        v-model="data.code"
                        :error-messages="validationErrors['code']"
                        :label="$t('settings.static.animalCode')"
                        dense
                        outlined
                    ></v-text-field>

                    <v-textarea
                        v-model="data.notes"
                        :error-messages="validationErrors['notes']"
                        :label="$t('settings.static.notes')"
                        dense
                        outlined
                    ></v-textarea>

                    <company-select
                        v-model="data.company_id"
                        :label="$t('general.static.company')"
                        :validation-errors="validationErrors['company_id']"
                    ></company-select>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions v-if="data" class="px-6 py-5">
                    <v-spacer></v-spacer>

                    <v-btn text @click="$emit('input', false)">
                        {{ $t('general.actions.cancel') }}
                    </v-btn>

                    <v-btn
                        :loading="saving || findingExistingAnimal"
                        color="primary"
                        @click="save()"
                    >
                        {{ exists ? $t('general.actions.save') : $t('general.actions.create') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="showConfirmMergeDialog"
            max-width="400"
        >
            <v-card>
                <v-card-title>
                    {{ $t('settings.static.confirmAnimalMerge') }}
                </v-card-title>

                <v-card-text>
                    {{ $t('settings.static.confirmAnimalMergeDescription') }}
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions v-if="data" class="px-6 py-5">
                    <v-spacer></v-spacer>

                    <v-btn text @click="showConfirmMergeDialog = false">
                        {{ $t('general.actions.cancel') }}
                    </v-btn>

                    <v-btn
                        color="primary"
                        @click="confirmMerge()"
                    >
                        {{ $t('general.actions.merge') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
import CompanySelect from '@/js/components/CompanySelect';
import AnimalTypeSelect from '@/js/components/AnimalTypeSelect';

export default {
    components: {AnimalTypeSelect, CompanySelect},

    props: {
        value: Boolean,
        animal: Object,
    },

    data() {
        return {
            data: null,

            showConfirmMergeDialog: false,
            mergeConfirmed: false,

            saving: false,

            validationErrors: {},

            animalExistsInCompany: false,
            findingExistingAnimal: false,
        }
    },

    computed: {
        activeCompany() {
            return this.$root.activeCompany;
        },

        exists() {
            return !this.data || !!this.data.id;
        },

        changedCompany() {
            if (!this.animal) return true;

            return this.animal.company_id != this.data.company_id;
        },
    },

    watch: {
        value() {
            this.data = {
                company_id: this.activeCompany ? this.activeCompany.id : null,
                animal_type_slug: null,
                code: null,
                notes: null,
                ..._.cloneDeep(this.animal)
            };

            this.validationErrors = {};
        },
    },

    methods: {
        confirmMerge(confirmed = true) {
            this.mergeConfirmed = confirmed;
            this.showConfirmMergeDialog = false;

            this.save(true);
        },

        async save(skipExistsCheck = false) {
            if (!skipExistsCheck) {
                await this.findExistingAnimal();

                if (this.animalExistsInCompany) {
                    return this.showConfirmMergeDialog = true;
                }
            }

            this.saving = true;

            this.data.merge_if_exists = this.mergeConfirmed;

            let request = this.exists
                ? this.update()
                : this.create();

            request.then((response) => {
                let message = this.exists
                    ? this.$t('settings.static.animalUpdated')
                    : this.$t('settings.static.animalCreated');

                this.$root.showSnackbar(message, 'success');

                this.$emit(this.exists ? 'updated' : 'created');

                this.$emit('input', false);
            }).catch((error) => {
                if (error.response.status == 422) {
                    this.validationErrors = error.response.data.errors;
                }
            }).finally(() => {
                this.saving = false;
            });
        },

        create() {
            return axios.post(`/api/animals`, this.data);
        },

        update() {
            return axios.put(`/api/animals/${this.data.id}`, this.data);
        },

        async findExistingAnimal() {
            this.animalExistsInCompany = false;

            if (!this.changedCompany) return;

            this.findingExistingAnimal = true;

            let parameters = [
                `where=[code|=|${this.data.code},company_id|=|${this.data.company_id}]`,
            ];

            await axios.get(`/api/animals?${_.join(parameters, '&')}`)
                .then((response) => {
                    this.animalExistsInCompany = response.data.total > 0;
                })
                .catch(() => {

                })
                .finally(() => {
                    this.findingExistingAnimal = false;
                });
        }
    }
}
</script>
