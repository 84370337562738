<template>

    <v-autocomplete
        :error-messages="validationErrors"
        :filter="filter"
        :items="countries"
        :label="$t('general.static.country')"
        :value="value"
        autocomplete="new"
        dense
        disable-lookup
        item-text="native"
        item-value="code"
        outlined
        @input="$emit('input', $event)"
    >
        <template v-slot:selection="{ item }">
            {{ item.native }}
            <small class="mx-1 grey--text">{{ item.name }}</small>
        </template>

        <template v-slot:item="{ item }">
            <v-list-item-content>
                <v-list-item-title>
                    {{ item.native }}
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{ item.name }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </v-autocomplete>

</template>

<script>
export default {
    props: {
        value: String,
        validationErrors: Array
    },

    computed: {
        countries() {
            return _.map(this.$root.countries, (country, code) => {
                country.code = code;

                return country;
            });
        }
    },

    methods: {
        filter(item, queryText, itemText) {
            return item.native.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
                || item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
        }
    }
}
</script>
