<template>

    <v-container :fluid="$vuetify.breakpoint.mdAndDown">
        <v-row class="justify-space-between align-center">
            <v-col class="display-1 primary--text font-weight-medium d-flex flex-wrap">
                {{ $t('cleaningAndDisinfection.static.cleaningAndDisinfection') }}

                <div class="px-3 align-self-center">
                    <v-img v-if="module" :src="module.outlinedIcon" contain height="32" width="32"/>
                </div>

                <v-fade-transition>
                    <small v-if="company" class="secondary--text text--lighten-2 font-weight-regular pt-1 mb-n2">
                        - {{ company.name }}
                        <small v-if="location">
                            - {{ location.name }}
                        </small>
                    </small>
                </v-fade-transition>
            </v-col>

            <v-col class="shrink d-flex">
                <v-btn
                    color="primary"
                    text
                    @click="$router.back()"
                >
                    <v-icon left>
                        mdi-arrow-left
                    </v-icon>
                    {{ $t('general.actions.back') }}
                </v-btn>
            </v-col>
        </v-row>

        <v-row class="align-stretch">
            <v-col cols="12" md="6">
                <checkpoints-table :loading="loading" :location="location"/>
            </v-col>

            <v-col cols="12" md="6">
                <status :loading="loading" :location="location"/>
            </v-col>
            <v-col cols="12" md="6">
                <checkpoints-chart :loading="loading || loadingChartData" :location="location" @next="nextMonth"
                                   @previous="previousMonth"/>
            </v-col>

            <v-col cols="12" md="6">
                <checkpoint-images :loading="loading" :location="location"/>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
import PieChart from '@/js/components/charts/PieChart';

import CheckpointStatusMixin from '@/js/mixins/checkpointStatus';
import CheckpointsTable from '@/js/pages/modules/cleaning-pigs/location/details/CheckpointsTable';
import Status from '@/js/pages/modules/cleaning-pigs/location/details/Status';
import CheckpointsChart from '@/js/pages/modules/cleaning-pigs/location/details/CheckpointsChart';
import CheckpointImages from '@/js/pages/modules/cleaning-pigs/location/details/CheckpointImages';

export default {
    components: {CheckpointImages, CheckpointsChart, Status, CheckpointsTable, PieChart},

    mixins: [CheckpointStatusMixin],

    props: {
        companyId: Number,
        locationId: Number,
    },

    data() {
        return {
            loading: false,
            loadingCompany: false,
            loadingChartData: false,

            date: moment(),

            location: null,
            company: null,
        }
    },

    computed: {
        queryParameters() {
            let parameters = [
                `appends=[cleaningPigsStatistics]`,
            ];

            return parameters;
        },

        user() {
            return this.$root.user;
        },

        module() {
            return _.find(this.$root.modules, {slug: 'cleaning_pigs'});
        }
    },

    watch: {
        queryParameters: {
            handler() {
                this.load();
            },
            deep: true,
            immediate: true,
        },
    },

    created() {
        this.loadCompany();
    },

    methods: {
        load() {
            if (this.loading) return;

            this.loading = true;

            axios.get(`/api/locations/${this.locationId}?${_.join(this.queryParameters, '&')}`)
                .then((response) => {
                    this.location = response.data;
                })
                .catch(() => {

                })
                .finally(() => {
                    this.loading = false;
                });
        },

        loadChartData() {
            if (this.loadingChartData) return;

            this.loadingChartData = true;

            let parameters = [
                `getCleaningPigsMonthlyScoresStatistics=1`,
                `startDate=${this.date.clone().subtract(1, 'year').add(1, 'month').startOf('month').format('YYYY-MM-DD')}`,
                `endDate=${this.date.clone().endOf('month').format('YYYY-MM-DD')}`,
            ];

            axios.get(`/api/locations/${this.locationId}?${_.join(parameters, '&')}`)
                .then((response) => {
                    this.location.cleaningPigsStatistics.monthlyCheckpoints = response.data.cleaningPigsStatistics.monthlyCheckpoints;
                })
                .catch(() => {

                })
                .finally(() => {
                    this.loadingChartData = false;
                });
        },

        loadCompany() {
            if (this.loadingCompany) return;

            this.loadingCompany = true;

            axios.get(`/api/companies/${this.companyId}`)
                .then((response) => {
                    this.company = response.data;
                })
                .catch(() => {

                })
                .finally(() => {
                    this.loadingCompany = false;
                });
        },

        previousMonth() {
            this.date = this.date.subtract(1, 'month');

            this.loadChartData();
        },

        nextMonth() {
            this.date = this.date.add(1, 'month');

            this.loadChartData();
        },
    }
}
</script>
