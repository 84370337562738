<template>

    <v-container :fluid="$vuetify.breakpoint.mdAndDown">
        <v-row class="justify-space-between align-center">
            <v-col class="display-1 primary--text font-weight-medium d-flex flex-wrap">
                {{ $t('cleaningAndDisinfection.static.cleaningAndDisinfection') }}

                <div class="px-3 align-self-center">
                    <v-img v-if="module" :src="module.outlinedIcon" contain height="32" width="32"/>
                </div>

                <v-fade-transition>
                    <small v-if="company" class="secondary--text text--lighten-2 font-weight-regular pt-1 mb-n2">
                        - {{ company.name }}
                        <small v-if="location">
                            - {{ location.name }}
                        </small>
                    </small>
                </v-fade-transition>
            </v-col>

            <v-col class="shrink d-flex">
                <v-btn
                    color="primary"
                    text
                    @click="$router.back()"
                >
                    <v-icon left>
                        mdi-arrow-left
                    </v-icon>
                    {{ $t('general.actions.back') }}
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col
                v-for="(day, index) in dayScores"
                :key="day.date.format('DD-MM-YYYY')"
                cols="12"
            >
                <div v-if="index == dayScores.length - 1" v-intersect="onLastElementIntersect"></div>

                <photos-card :date="day.date" :scores="day.scores"/>
            </v-col>

            <v-col cols="12">
                <v-fade-transition mode="out-in">
                    <v-card v-if="loading" key="0" loading>
                        <v-card-subtitle class="text-center">
                            {{
                                pagesLoaded <= 0 ? $t('general.static.loading') : $t('general.static.loadingMoreResults')
                            }}
                        </v-card-subtitle>
                    </v-card>

                    <v-card v-else-if="reachedEndOfPages" key="1" color="primary" dark>
                        <v-card-subtitle class="text-center">
                            {{ $t('general.static.noMoreResults') }}
                        </v-card-subtitle>
                    </v-card>
                </v-fade-transition>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
import CheckpointStatusMixin from '@/js/mixins/checkpointStatus';

import PieChart from '@/js/components/charts/PieChart';
import PhotosCard from '@/js/pages/modules/cleaning-poultry/location/photos/PhotosCard';

export default {
    components: {PhotosCard, PieChart},

    mixins: [CheckpointStatusMixin],

    props: {
        companyId: Number,
        locationId: Number,
    },

    data() {
        return {
            loading: false,
            loadingCompany: false,
            loadingLocation: false,

            scores: [],
            pagesLoaded: 0,
            reachedEndOfPages: false,

            location: null,
            company: null,
        }
    },

    computed: {
        dayScores() {
            let dayScores = _.groupBy(this.scores, (score) => {
                return moment(score.saved_at).format('YYYY-MM-DD');
            });

            dayScores = _.mapValues(dayScores, (scores, date) => {
                return {
                    date: moment(date),
                    scores: _.sortBy(scores, 'checkpoint_number')
                }
            });

            return _.sortBy(dayScores, 'date').reverse();
        },

        user() {
            return this.$root.user;
        },

        module() {
            return _.find(this.$root.modules, {slug: 'cleaning_poultry'});
        }
    },

    created() {
        this.loadCompany();
        this.loadLocation();

        this.loadNextPage();
    },

    methods: {
        onLastElementIntersect(entries, observer, isIntersecting) {
            if (isIntersecting) this.loadNextPage();
        },

        loadNextPage() {
            if (this.loading) return;

            if (this.reachedEndOfPages) return;

            this.loading = true;

            let parameters = [
                `paginate=40`,
                `page=${this.pagesLoaded + 1}`,
                `where=[location_id|=|${this.locationId}]`,
                `appends=[downloadUrls,base64Images]`,
                `order_by=saved_at`,
                `order=desc`,
            ];

            axios.get(`/api/cleaning-poultry-scores?${_.join(parameters, '&')}`)
                .then((response) => {
                    this.pagesLoaded++;

                    if (_.isEmpty(response.data.items)) {
                        this.reachedEndOfPages = true;

                        return;
                    }

                    this.scores.push(...response.data.items);
                })
                .catch(() => {

                })
                .finally(() => {
                    this.loading = false;
                });
        },

        loadLocation() {
            if (this.loadingLocation) return;

            this.loadingLocation = true;

            axios.get(`/api/locations/${this.locationId}?${_.join(this.queryParameters, '&')}`)
                .then((response) => {
                    this.location = response.data;
                })
                .catch(() => {

                })
                .finally(() => {
                    this.loadingLocation = false;
                });
        },

        loadCompany() {
            if (this.loadingCompany) return;

            this.loadingCompany = true;

            axios.get(`/api/companies/${this.companyId}`)
                .then((response) => {
                    this.company = response.data;
                })
                .catch(() => {

                })
                .finally(() => {
                    this.loadingCompany = false;
                });
        },
    }
}
</script>
