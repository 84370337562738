<template>

    <v-card class="fill-height">
        <v-overlay :value="loading" absolute color="white"></v-overlay>

        <v-card-title class="title primary--text">
            {{ $t('checkpoints.static.averageCheckpoints') }}
        </v-card-title>

        <v-card-text class="px-0 d-flex align-center overflow-x-auto">
            <v-btn class="mr-n3" icon @click="$emit('previous')">
                <v-icon large>
                    mdi-chevron-left
                </v-icon>
            </v-btn>

            <div class="flex-grow-1">
                <line-chart
                    :animate="false"
                    :chart-data="{ labels: labels, datasets: datasetsWithBackgrounds }"
                    :height="344"
                    :max="3"
                    :min="0"
                    chart-id="cleaning-poultry-location-checkpoints-chart"
                ></line-chart>
            </div>

            <v-btn class="ml-n3" icon @click="$emit('next')">
                <v-icon large>
                    mdi-chevron-right
                </v-icon>
            </v-btn>
        </v-card-text>
    </v-card>

</template>

<script>
import LineChart from '@/js/components/charts/LineChart';

import CheckpointStatusMixin from '@/js/mixins/checkpointStatus';

export default {
    components: {LineChart},

    mixins: [CheckpointStatusMixin],

    props: {
        location: Object,

        loading: Boolean
    },

    computed: {
        datasetsWithBackgrounds() {
            let backgroundDatasets = _.map(this.checkpointStatusScores, (score, scoreIndex) => {
                if (score < 0) return;

                let fill = 'origin';

                if (scoreIndex - 1 > 0) {
                    fill = `${scoreIndex - 2}`;
                }

                return {
                    data: _.map(this.location?.cleaningPoultryStatistics?.monthlyCheckpoints ?? [], () => score),
                    backgroundColor: `${this.checkpointStatusColors[scoreIndex]}30`,
                    fill: fill,
                    borderWidth: 0,
                    pointRadius: 0
                };
            });

            return [
                ..._.filter(backgroundDatasets),
                ...this.datasets,
            ];
        },

        datasets() {
            if (!this.location) return [];

            let scoreVariables = {
                'drinking_lines_score': this.$t('cleaningAndDisinfection.static.drinkingLines'),
                'floors_score': this.$t('cleaningAndDisinfection.static.floor'),
                'normalized_swap_score': this.$t('cleaningAndDisinfection.static.swapScore')
            };

            let i = 0;

            return _.map(scoreVariables, (label, scoreVariable) => {
                let scores = _.map(this.location.cleaningPoultryStatistics.monthlyCheckpoints, (monthCheckpoints) => {
                    let scoresSum = _.sumBy(monthCheckpoints.checkpoints, scoreVariable);

                    let scoresCount = _.filter(monthCheckpoints.checkpoints, (checkpoint) => {
                        return checkpoint[scoreVariable] !== null;
                    }).length;

                    if (scoresCount <= 0) return null;

                    return _.round(scoresSum / scoresCount, 2);
                });

                return {
                    label: label,
                    data: scores,
                    backgroundColor: 'transparent',
                    borderColor: this.chartColors[i++],
                    pointBackgroundColor: '#ffffff',
                    borderWidth: 2,
                    lineTension: 0
                }
            });
        },

        chartColors() {
            return [
                this.$vuetify.theme.currentTheme.info,
                this.$vuetify.theme.currentTheme.error,
                this.$vuetify.theme.currentTheme.primary,
            ];
        },

        labels() {
            if (!this.location) return [];

            return _.map(this.location.cleaningPoultryStatistics.monthlyCheckpoints, (monthCheckpoints) => {
                return moment(monthCheckpoints.date).month();
            });
        },
    }
}
</script>
