<template>

    <v-dialog
        :value="value"
        max-width="500"
        @input="$emit('input', $event)"
    >
        <v-card>
            <v-card-title>
                {{ exists ? $t('settings.static.editUser') : $t('settings.static.createUser') }}

                <v-spacer></v-spacer>

                <small v-if="data && data.updated_at" class="caption grey--text font-weight-regular">
                    {{
                        $t('general.static.lastUpdatedAt', {date: $moment(data.updated_at).format('DD-MM-YYYY HH:mm')})
                    }}
                </small>
            </v-card-title>

            <v-card-text v-if="data" class="pb-0">
                <v-text-field
                    v-model="data.first_name"
                    :error-messages="validationErrors['first_name']"
                    :label="$t('general.static.firstName')"
                    dense
                    outlined
                ></v-text-field>

                <v-text-field
                    v-model="data.last_name"
                    :error-messages="validationErrors['last_name']"
                    :label="$t('general.static.lastName')"
                    dense
                    outlined
                ></v-text-field>

                <v-text-field
                    v-model="data.email"
                    :error-messages="validationErrors['email']"
                    :label="$t('settings.static.email')"
                    dense
                    outlined
                ></v-text-field>

                <language-select
                    v-model="data.language_code"
                    :validation-errors="validationErrors['language_code']"
                    text
                ></language-select>

                <role-select
                    v-model="data.role_slug"
                    :validation-errors="validationErrors['role_slug']"
                ></role-select>

                <company-select
                    v-model="data.company_ids"
                    :validation-errors="validationErrors['company_ids']"
                    multiple
                ></company-select>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions v-if="data" class="px-6 py-5">
                <v-spacer></v-spacer>

                <v-btn text @click="$emit('input', false)">
                    {{ $t('general.actions.cancel') }}
                </v-btn>

                <v-btn
                    :loading="saving"
                    color="primary"
                    @click="save"
                >
                    {{ exists ? $t('general.actions.save') : $t('general.actions.create') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>
import LanguageSelect from '@/js/components/LanguageSelect';
import RoleSelect from '@/js/components/RoleSelect';
import CompanySelect from '@/js/components/CompanySelect';

export default {
    components: {LanguageSelect, RoleSelect, CompanySelect},

    props: {
        value: Boolean,
        user: Object,
    },

    data() {
        return {
            data: null,

            saving: false,

            validationErrors: {},
        }
    },

    computed: {
        exists() {
            return !this.data || !!this.data.id;
        }
    },

    watch: {
        value() {
            this.data = {
                company_ids: [],
                language_code: 'en',
                first_name: null,
                last_name: null,
                email: null,
                ..._.cloneDeep(this.user)
            };

            if (this.user && this.user.companies) {
                this.data.company_ids = _.map(this.user.companies, 'id');
            }

            this.validationErrors = {};
        }
    },

    methods: {
        save() {
            this.saving = true;

            let request = this.exists
                ? this.update()
                : this.create();

            request.then((response) => {
                let message = this.exists
                    ? this.$t('settings.static.userUpdated')
                    : this.$t('settings.static.userCreated');

                this.$root.showSnackbar(message, 'success');

                this.$emit(this.exists ? 'updated' : 'created');

                this.$emit('input', false);
            }).catch((error) => {
                if (error.response.status == 422) {
                    this.validationErrors = error.response.data.errors;
                }
            }).finally(() => {
                this.saving = false;
            });
        },

        create() {
            return axios.post(`/api/users`, this.data);
        },

        update() {
            return axios.put(`/api/users/${this.data.id}`, this.data);
        }
    }
}
</script>
