<template>

    <v-dialog
        :value="value"
        max-width="400"
        @input="$emit('input', $event)"
    >
        <v-card>
            <v-card-title>
                {{ $t('settings.static.confirmRemoveUser') }}
            </v-card-title>

            <v-card-text>
                {{ $t('settings.static.confirmRemoveUserDescription') }}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="px-6 py-5">
                <v-spacer></v-spacer>

                <v-btn text @click="$emit('input', false)">
                    {{ $t('general.actions.cancel') }}
                </v-btn>

                <v-btn
                    :loading="removing"
                    color="primary"
                    @click="removeUserFromCompany"
                >
                    {{ $t('general.actions.remove') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>
export default {
    props: {
        value: Boolean,
        user: Object,
    },

    data() {
        return {
            removing: false
        }
    },

    computed: {
        activeCompany() {
            return this.$root.activeCompany;
        },
    },

    methods: {
        removeUserFromCompany() {
            this.removing = true;

            axios.put(`/api/users/${this.user.id}`, {
                company_id: this.activeCompany.id,
                remove: true
            })
                .then(() => {
                    this.$root.showSnackbar(this.$t('settings.static.userRemovedFromCompany'), 'success');

                    this.$emit('removed');

                    this.$emit('input', false);
                })
                .catch(() => {
                    this.$root.showSnackbar(this.$t('settings.static.userRemoveFromCompanyFailed'), 'error');
                })
                .finally(() => {
                    this.removing = false;
                });
        }
    }
}
</script>
