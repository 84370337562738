<template>

    <v-card class="fill-height">
        <v-overlay :value="loading" absolute color="white"></v-overlay>

        <v-card-title class="title primary--text">
            {{ $t('checkpoints.static.checkpointImages') }}

            <v-spacer></v-spacer>

            <v-btn v-if="location"
                   :to="{ name: 'CleaningPoultryLocationPhotos', params: { companyId: location.company_id, locationId: location.id } }"
                   color="primary" outlined small>
                {{ $t('general.actions.photoCollage') }}
            </v-btn>
        </v-card-title>

        <v-sheet color="secondary">
            <v-carousel v-if="locationHasScores" v-model="slide" height="360" hide-delimiters>
                <template v-for="score in location.cleaningPoultryStatistics.lastScores">
                    <v-carousel-item
                        v-for="(filePathKey, key) in score.filePathKeys"
                        v-if="score.base64Images[key] || score.downloadUrls[key]"
                        :key="score.id + key"
                        :src="score.base64Images[key] ? `data:image/png;base64, ${score.base64Images[key]}` : score.downloadUrls[key]"
                        contain
                    >
                        <v-card class="relative" color="transparent" elevation="0" height="32">
                            <v-overlay absolute class="font-weight-medium" opacity="0.4">
                                {{ $t('checkpoints.static.checkpoint') }} {{ score.checkpoint_number + 1 }},
                                {{ $moment(score.saved_at).format('DD-MM-YYYY') }}
                            </v-overlay>
                        </v-card>
                    </v-carousel-item>
                </template>
            </v-carousel>
        </v-sheet>
    </v-card>

</template>

<script>
import CheckpointStatusMixin from '@/js/mixins/checkpointStatus';

export default {
    mixins: [CheckpointStatusMixin],

    props: {
        location: Object,

        loading: Boolean
    },

    computed: {
        locationHasScores() {
            return this.location && !_.isEmpty(this.location.cleaningPoultryStatistics.lastScores);
        }
    },

    data() {
        return {
            slide: null
        }
    },
}
</script>
