<template>

    <v-app>
        <v-app-bar app elevation="1">
            <v-img
                :src="require('@/img/hy-check-logo.svg')"
                contain
                height="100%"
                position="center center"
            ></v-img>
        </v-app-bar>

        <v-main>
            <v-overlay
                :opacity="0.3"
                :value="true"
                color="white"
                z-index="0"
            ></v-overlay>

            <v-container fill-height fluid>
                <v-layout align-center column justify-center>
                    <v-slide-y-reverse-transition leave-absolute>
                        <router-view></router-view>
                    </v-slide-y-reverse-transition>

                    <v-card class="mt-3" color="transparent" flat @click="openMobileApp">
                        <img v-if="isAndroid" src="/img/google-play-badge.svg" width="200px"/>
                        <img v-if="isIOS" src="/img/app-store-badge.svg" width="200px"/>
                    </v-card>
                </v-layout>
            </v-container>
        </v-main>

        <snackbar
            v-model="$root.snackbar.text"
            :type="$root.snackbar.type"
        ></snackbar>
    </v-app>

</template>

<script>
import Snackbar from '@/js/components/Snackbar';

import UserAgentMixin from '@/js/mixins/userAgent';
import MobileMixin from '@/js/mixins/mobile';

export default {
    components: {Snackbar},

    mixins: [UserAgentMixin, MobileMixin],
}
</script>
